import React, { useEffect, useState } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';


function AdminVehicleCategory() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getVehiclecategory()
    }, [])


    // Add Vechicle Category
    const [vehiclecategory, setvehiclecategory] = useState("")
    const [vehicleImage, setvehicleImage] = useState("")
    const AddVechicleCategory = async () => {

        if (!vehicleImage) {
            return notify1("Please Select Vehicle Image")
        }
        if (!vehiclecategory) {
            return notify1("Please Enter Vehicle Category")
        }
        try {
            const formData = new FormData();
            formData.append("vehiclecategory", vehiclecategory);
            formData.append("vehicleImage", vehicleImage);

            const config = {
                url: "/addvechiclecategory",
                baseURL: "https://hayyyak.com/api/admin/taxi",
                method: "post",
                headers: { "Content-Type": "multipart/form-data" },
                data: formData,
            };

            const response = await axios(config);

            if (response.status === 200) {
                notify(response.data.success);
                getVehiclecategory(); // Assuming this is a function to fetch the updated list of vehicle categories
                handleClose();
                setvehiclecategory("")
                setvehicleImage("")
            }
        } catch (error) {
            notify2(error.response.data.error);
        }
    };

    // EDIT Vehicle Category 
    const [editdata, seteditdata] = useState("")
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (item) => { setShow1(true); seteditdata(item) }

    const EditVechicleCategory = async () => {

        try {
            const formData = new FormData();
            formData.append("vehiclecategory", vehiclecategory);
            formData.append("vehicleImage", vehicleImage);

            const config = {
                url: "/editvechiclecategory/" + editdata?._id,
                baseURL: "https://hayyyak.com/api/admin/taxi",
                method: "put",
                headers: { "content-type": "multipart/form-data" },
                data: {
                    vehiclecategory: vehiclecategory,
                    vehicleImage: vehicleImage
                }
            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success)
                    getVehiclecategory();
                    handleClose1()
                    setvehiclecategory("")
                    setvehicleImage("")
                }
            })

        } catch (error) {
            console.log(error)
            notify2(error.response.data.error)
        }
    }

    // Get Vehicle Category Data
    const [getVehicleCat, setgetVehicleCat] = useState([])
    const getVehiclecategory = () => {
        axios.get("https://hayyyak.com/api/admin/taxi/getvechiclecategory")
            .then(function (response) {
                setgetVehicleCat(response.data.success);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // Delete 
    const [Data, setData] = useState({});
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (item) => { setShow2(true); setData(item); }

    const DeleteVechicleCategory = async () => {
        try {
            const config = {
                url: "/deletevehiclecategory/" + Data?._id,
                baseURL: "https://hayyyak.com/api/admin/taxi",
                method: 'delete',
                headers: { "Content-Type": "application/json" },
            };
            const response = await axios(config);
            if (response.status === 200) {
                notify(response.data.success);
                getVehiclecategory();
                handleClose2();
            }
        } catch (error) {
            notify2(error.response ? error.response.data.error : "An error occurred");
        }
    };

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2 mb-3'>
                    <p className="text-danger fs-4 fw-semibold">Vehicle Category :</p>
                    <button className='pink-btn' onClick={handleShow}>Add Activity</button>
                </div>

                {/* <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                    <div className="col-lg-2">
                        <label>From :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    <div className="col-lg-2">
                        <label>To :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    <div className="col-lg-2">
                        <button className="pink-btn">Submit</button>
                    </div>

                    <div className="input-group col-lg-4" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text" class="form-control" placeholder="Search..." aria-describedby="basic-addon1" />
                    </div>

                </div> */}

                <div >
                    <Table responsive bordered className='sdfsd-table-head'>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Vehicle Image</th>
                                <th>Category</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getVehicleCat?.map((item, i) => {
                                return (<>
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td><img src={`https://hayyyak.com/VehicleCategory/${item?.vehicleImage}`} alt="" style={{ width: "70px", height: '70px', imageRendering: 'pixelated' }} /></td>
                                        <td>{item?.vehiclecategory}</td>
                                        <td>
                                            <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                <BiSolidEdit className='text-primary' onClick={() => handleShow1(item)} />
                                                <MdDelete className='text-danger' onClick={() => handleShow2(item)} />
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            })}

                        </tbody>
                    </Table>
                </div>
            </Container>

            {/* Add Activity Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add Activity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Vechicle Image :</label>
                        <input
                            id='upload'
                            accept='image/*'
                            type="file"
                            placeholder="Category"
                            className="vi_0"
                            onChange={(e) => setvehicleImage(e.target.files[0])}
                        />
                    </div>
                    <div className=" mt-2">
                        <label className='fw-bold'>Enter Vechicle Category :</label>
                        <input
                            type="text"
                            placeholder="Category"
                            className="vi_0"
                            onChange={(e) => setvehiclecategory(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AddVechicleCategory}>
                        Add Category
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Activity */}
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Activity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" mb-2">
                        <label className='fw-bold' htmlFor='upload'>Vechicle Image :</label>
                        <input
                            id='upload'
                            accept='image/*'
                            type="file"
                            placeholder="Category"
                            className="vi_0"
                            onChange={(e) => setvehicleImage(e.target.files[0])}
                        />
                    </div>
                    <div className=" mt-2">
                        <label className='fw-bold'>Enter Vechicle Category :</label>
                        <input
                            type="text"
                            placeholder="Category"
                            className="vi_0"
                            onChange={(e) => setvehiclecategory(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger"
                        onClick={EditVechicleCategory}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Modal */}

            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={DeleteVechicleCategory} ><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default AdminVehicleCategory
