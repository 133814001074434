import React, { useEffect, useRef, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import { AiFillEye } from "react-icons/ai";
import { Country, State, City } from "country-state-city";
import parse from "html-react-parser";
import { FaRegEdit } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
function VendorRestaurantAndCafe() {
  const RestaurantVendor = JSON.parse(
    sessionStorage.getItem("RestaurantVendor")
  );
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.warn(data);
  const notify2 = (data) => toast.error(data);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);



  const [Country1, setCountry1] = useState("");
  const [State1, setState1] = useState("");
  const [City1, setCity1] = useState("");
  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityList, setCityList] = useState([]);

  useEffect(() => {
    const countries = Country.getAllCountries();
    setCountryList(countries);
  }, []);

  useEffect(() => {
    if (Country1) {
      const countryCode = Country1.split(",")[0]; // Extracting the country code
      const states = State.getStatesOfCountry(countryCode);
      setStateList(states);
    }
  }, [Country1]);

  useEffect(() => {
    if (Country1 && State1) {
      const countryCode = Country1.split(",")[0]; // Extracting the country code
      const stateCode = State1.split(",")[0]; // Extracting the country code
      const cities = City.getCitiesOfState(countryCode, stateCode);
      setCityList(cities);
    }
  }, [Country1, State1]);
  // // Add Restaurant and cafe

  const [RestaturantType, setRestaturantType] = useState("");
  const [RestaurantName, setRestaurantName] = useState("");
  const [MobileN, setMobileN] = useState("");
  const [Address, setAddress] = useState("");
  const [LocationLink, setLocationLink] = useState("");
  const [SatPrice, setSatPrice] = useState("");
  const [Discount, setDiscount] = useState("");
  const [Latitude, setLatitude] = useState("");
  const [Logitude, setLogitude] = useState("");
  const [Facilities, setFacilities] = useState("");
  const [Kitchentype, setKitchentype] = useState("");
  const [OpeningTime, setOpeningTime] = useState("");
  const [placeknownfor, setplaceknownfor] = useState("");
  const [PopularDish, setPopularDish] = useState("");

  const AddRestuarentCafe = async () => {
    // if (!RestaturantType) {
    //   return notify1("Please Enter Vehicle Model");
    // }

    const obj = {
      restoid: RestaurantVendor?._id,
      restotype: RestaturantType,
      restoname: RestaurantName,
      mobile: MobileN,
      Country: Country1,
      state: State1,
      city: City1,
      Address: Address,
      location: LocationLink,
      Price: SatPrice,
      Discount: Discount,
      Latitude: Latitude,
      Longitude: Logitude,
      Facilities: Facilities,
      kitchentype: Kitchentype,
      restoopens: OpeningTime,
      placeknownfor: placeknownfor,
      populardishes: PopularDish,
    };
    try {
      const configRestaurant = {
        url: "/resto/addrestandcafe",
        method: "post",
        baseURL: "https://hayyyak.com/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: obj,
      };
      const res = await axios(configRestaurant);
      if (res.status === 200) {
        for (let i = 0; i < Gallery.length; i++) {
          const configGallery = {
            url: "/resto/addgallery",
            method: "put",
            baseURL: "https://hayyyak.com/api/admin",
            headers: { "content-type": "multipart/form-data" },
            data: {
              restorentid: res.data.sucess?._id,
              image1: Gallery[i]?.image1,
            },
          };
          await axios(configGallery);
        }
        for (let i = 0; i < Menu.length; i++) {
          const configMenu = {
            url: "/resto/addmenu",
            method: "put",
            baseURL: "https://hayyyak.com/api/admin",
            headers: { "content-type": "multipart/form-data" },
            data: {
              restorentid: res.data.sucess?._id,
              image: Menu[i]?.image,
            },
          };
          await axios(configMenu);
        }
        notify(res.data.success);
        handleClose();
        GetByIdRestaurantCafe();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Add Gallery Image
  const [Image, setImage] = useState(null); // Changed from "" to null
  const [Gallery, setGallery] = useState([]);
  const [imageURL2, setimageURL2] = useState(null);
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]); // Update Image state with the selected file
      setimageURL2(URL.createObjectURL(event.target.files[0]));
    }
  };

  const AddGalleryImage = async () => {
    if (!Image) {
      return alert("Please, Select Image");
    }
    try {
      const obj = {
        image1: Image, // Use Image state here
        imgUrl: imageURL2,
      };
      setGallery([...Gallery, obj]); // Update Gallery state properly
      setImage(null); // Reset Image state after adding to Gallery
    } catch (error) {
      console.log(error);
    }
  };

  const removeItem2 = (val) => {
    const updatedGallery = [...Gallery];
    updatedGallery.splice(val, 1);
    setGallery(updatedGallery); // Update Gallery state after removing item
  };
  // Add Gallery Image
  const [Image1, setImage1] = useState(null); // Changed from "" to null
  const [Menu, setMenu] = useState([]);
  const [imageURL3, setimageURL3] = useState(null);
  const onImageChange3 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage1(event.target.files[0]); // Update Image state with the selected file
      setimageURL3(URL.createObjectURL(event.target.files[0]));
    }
  };

  const AddMenuImage = async () => {
    if (!Image1) {
      return alert("Please, Select Image");
    }
    try {
      const obj = {
        image: Image1, // Use Image state here
        imgUrl: imageURL3,
      };
      setMenu([...Menu, obj]); // Update Gallery state properly
      setImage1(null); // Reset Image state after adding to Gallery
    } catch (error) {
      console.log(error);
    }
  };

  const removeItem3 = (val) => {
    const updatedMenu = [...Menu];
    updatedMenu.splice(val, 1);
    setMenu(updatedMenu); // Update Gallery state after removing item
  };

  // // EDIT Vehicle Category

  const [editdata, seteditdata] = useState({});
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const EditRestoCafe = async () => {
    try {
      const obj = {
        restotype: RestaturantType,
        restoname: RestaurantName,
        mobile: MobileN,
        Country: Country1,
        state: State1,
        city: City1,
        Address: Address,
        location: LocationLink,
        Price: SatPrice,
        Discount: Discount,
        Latitude: Latitude,
        Longitude: Logitude,
        Facilities: Facilities,
        kitchentype: Kitchentype,
        restoopens: OpeningTime,
        placeknownfor: placeknownfor,
        populardishes: PopularDish,
      };
      const config = {
        url: "/updaterestoandcafe/" + editdata?._id,
        baseURL: "https://hayyyak.com/api/admin/resto",
        method: "put",
        headers: { "content-type": "application/json" },
        data: obj,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          notify(res.data.success);
          handleClose1();
          GetByIdRestaurantCafe();
        }
      });
    } catch (error) {
      console.log(error);
      notify2(error.response.data.error);
    }
  };

  useEffect(() => {
    if (editdata) {
      setRestaturantType(editdata?.restotype || "");
      setRestaurantName(editdata?.restoname || "");
      setMobileN(editdata?.mobile || "");
      setCountry1(editdata?.Country || "");
      setState1(editdata?.state || "");
      setCity1(editdata?.city || "");
      setAddress(editdata?.Address || "");
      setLocationLink(editdata?.location || "");
      setSatPrice(editdata?.Price || "");
      setDiscount(editdata?.Discount || "");
      setLatitude(editdata?.Latitude || "");
      setLogitude(editdata?.Longitude || "");
      setFacilities(editdata?.Facilities || "");
      setKitchentype(editdata?.kitchentype || "");
      setOpeningTime(editdata?.restoopens || "");
      setplaceknownfor(editdata?.placeknownfor || "");
      setPopularDish(editdata?.populardishes || "");
    }
  }, [editdata]);

  // // get Resto/Cafe data
  const [DataView, setDataView] = useState({});
  const [RestoCafe, setRestoCafe] = useState([]);
  const GetByIdRestaurantCafe = async () => {
    try {
      const res = await axios.get(
        `https://hayyyak.com/api/admin/resto/getrestoDetailsById/${RestaurantVendor?._id}`
      );
      if (res.status === 200) {
        setRestoCafe(res.data.resto);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetByIdRestaurantCafe();
  }, []);

  console.log("RestoCafe", RestoCafe, DataView);

  // // Delete
  const [Data, setData] = useState({});
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (id) => {
    setShow2(true);
    setData(id);
  };

  const DeleteRestoCafe = async () => {
    try {
      const config = {
        url: "/deleteresto/" + Data,
        baseURL: "https://hayyyak.com/api/admin/resto",
        method: "delete",
        headers: { "Content-Type": "application/json" },
      };
      const response = await axios(config);
      if (response.status === 200) {
        notify(response.data.sucess);
        GetByIdRestaurantCafe();
        handleClose2();
      }
    } catch (error) {
      notify2(error.response ? error.response.data.error : "An error occurred");
    }
  };

  const DeleteGalleryImg = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmed) {
      try {
        const config = {
          url: "/deletegallery/" + id,
          baseURL: "https://hayyyak.com/api/admin/resto",
          method: "delete",
          headers: { "Content-Type": "application/json" },
          data: {
            packid: DataView?._id,
          },
        };
        const response = await axios(config);
        if (response.status === 200) {
          notify(response.data.sucess);
          GetByIdRestaurantCafe();
          setDataView(response.data.deletegallery);
        }
      } catch (error) {
        notify2(
          error.response ? error.response.data.error : "An error occurred"
        );
      }
    } else {
      notify1("Sorry,Try again..");
    }
  };

  const DeleteMenuImg = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmed) {
      try {
        const config = {
          url: "/deletemenu/" + id,
          baseURL: "https://hayyyak.com/api/admin/resto",
          method: "delete",
          headers: { "Content-Type": "application/json" },
          data: {
            packid: DataView?._id,
          },
        };
        const response = await axios(config);
        if (response.status === 200) {
          notify(response.data.sucess);
          GetByIdRestaurantCafe();
          setDataView(response.data.deletemwnu);
        }
      } catch (error) {
        notify2(
          error.response ? error.response.data.error : "An error occurred"
        );
      }
    } else {
      notify1("Sorry,Try again..");
    }
  };

  const fileInputRef = useRef(null);
  const openFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is not initialized properly.");
    }
  };

  // Edit Gallery Images
  const [EditGalleryImageID, setEditGalleryImageID] = useState("");
  const EditGalleryInage = async (image1) => {
    try {
      const config = {
        url: "/resto/updategallery",
        method: "put",
        baseURL: "https://hayyyak.com/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          id: DataView?._id,
          galleryid: EditGalleryImageID,
          image1: image1,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify(res.data.msg);
        setDataView(res.data.success);
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };
  // Edit Gallery Images

  const EditMenuImage = async (image) => {
    try {
      const config = {
        url: "/resto/updatemenu",
        method: "put",
        baseURL: "https://hayyyak.com/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          id: DataView?._id,
          galleryid: EditGalleryImageID,
          image: image,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        notify(res.data.msg);
        setDataView(res.data.success);
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };

  // Add Gallery Image
  const fileInputRef1 = useRef(null);
  const openFile1 = () => {
    if (fileInputRef1.current) {
      fileInputRef1.current.click();
    } else {
      console.error("File input ref is not initialized properly.");
    }
  };
  const AddImageGallery = async (image1) => {
    try {
      const configGallery = {
        url: "/resto/addgallery",
        method: "put",
        baseURL: "https://hayyyak.com/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          restorentid: DataView?._id,
          image1: image1,
        },
      };
      let res = await axios(configGallery);
      if (res.status === 200) {
        notify(res.data.sucess);
        GetByIdRestaurantCafe();
        setDataView(res.data.updeteddata);
        handleShow3();
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };
  const AddImageMenu = async (image) => {
    try {
      const configGallery = {
        url: "/resto/addmenu",
        method: "put",
        baseURL: "https://hayyyak.com/api/admin",
        headers: { "content-type": "multipart/form-data" },
        data: {
          restorentid: DataView?._id,
          image: image,
        },
      };
      let res = await axios(configGallery);
      if (res.status === 200) {
        notify(res.data.sucess);
        GetByIdRestaurantCafe();
        // setDataView(res.data.updeteddata);
        //  handleShow4()
      }
    } catch (error) {
      notify2(error.response.data.error);
    }
  };
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        closeOnClick
        pauseOnHover
        draggable
      />
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2 mb-3">
          <p className="text-danger fs-4 fw-semibold">Restaurant / Cafe :</p>
          <button className="pink-btn" onClick={handleShow}>
            Add
          </button>
        </div>

        <div>
          <Table responsive bordered className="admin-table-head">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Type</th>
                <th>Name</th>
                <th>Mobile No.</th>
                <th>Address</th>
                <th>Starting Price</th>
                <th>Discount</th>
                <th>Location</th>
                <th>Facilities</th>
                <th>kitchen type</th>
                <th>Timing</th>
                <th>Place</th>
                <th>Popular Dishes</th>
                <th>Gallery</th>
                <th>Menu</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {RestoCafe?.map((item, i) => {
                return (
                  <>
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?.restotype}</td>
                      <td>{item?.restoname}</td>
                      <td>{item?.mobile}</td>
                      <td>
                        {item?.Address},{item?.city},{item?.state},
                        {item?.Country},{item?.Location}
                      </td>
                      <td>{item?.Price}</td>
                      <td>{item?.Discount}</td>
                      <td>
                        {item?.Latitude},{item?.Longitude}
                      </td>
                      <td>{parse(`<div>${item?.Facilities}</div>`)}</td>
                      <td>{parse(`<div>${item?.kitchentype}</div>`)}</td>
                      <td>{parse(`<div>${item?.restoopens}</div>`)}</td>
                      <td>{parse(`<div>${item?.placeknownfor}</div>`)}</td>
                      <td>{parse(`<div>${item?.populardishes}</div>`)}</td>
                      <td>
                        <div
                          className="fs-3 text-danger"
                          style={{ cursor: "pointer" }}
                        >
                          <AiFillEye
                            onClick={() => {
                              handleShow3(item);
                              setDataView(item);
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div
                          className="fs-3 text-danger"
                          style={{ cursor: "pointer" }}
                        >
                          <AiFillEye
                            onClick={() => {
                              handleShow4(item);
                              setDataView(item);
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div
                          className="d-flex gap-2 fs-4"
                          style={{ cursor: "pointer" }}
                        >
                          <BiSolidEdit
                            className="text-primary"
                            onClick={() => {
                              handleShow1();
                              seteditdata(item);
                            }}
                          />
                          <MdDelete
                            className="text-danger"
                            onClick={() => handleShow2(item?._id)}
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      {/* Add Activity Modal */}
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            Add Restaurant / Cafe
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Restaurant / Cafe :</label>
              <Form.Select
                onChange={(e) => setRestaturantType(e.target.value)}
                className="vi_0"
              >
                <option>Select</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Cafe">Cafe</option>
              </Form.Select>
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold"> Name Restaurant / Cafe :</label>
              <input
                type="text"
                placeholder="Enter name"
                className="vi_0"
                onChange={(e) => setRestaurantName(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Mobile Number :</label>
              <input
                type="number"
                placeholder="Number"
                className="vi_0"
                onChange={(e) => setMobileN(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Country :</label>
              <Form.Select
                onChange={(e) => setCountry1(e.target.value)}
                className="vi_0"
              >
                <option>Select</option>
                {CountryList?.map((country) => {
                  return (
                    <option value={`${country?.isoCode},${country?.name}`}>
                      {country?.name}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">State :</label>
              <Form.Select
                onChange={(e) => setState1(e.target.value)}
                className="vi_0"
              >
                <option>Select</option>
                {StateList?.map((state) => {
                  return (
                    <option value={`${state?.isoCode},${state?.name}`}>
                      {state?.name}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">City :</label>
              <Form.Select
                onChange={(e) => setCity1(e.target.value)}
                className="vi_0"
              >
                <option>Select</option>
                {CityList?.map((city) => {
                  return <option value={city?.name}>{city?.name}</option>;
                })}
              </Form.Select>
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold"> Porparty Address :</label>
              <input
                type="text"
                placeholder="Porparty Address"
                className="vi_0"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Property Location(MAP Link) :</label>
              <input
                type="Link"
                placeholder="Link"
                className="vi_0"
                onChange={(e) => setLocationLink(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Starting Price :</label>
              <input
                type="number"
                placeholder="Starting Price"
                className="vi_0"
                onChange={(e) => setSatPrice(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Discount :</label>
              <input
                type="number"
                placeholder="discount%"
                className="vi_0"
                onChange={(e) => setDiscount(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Latitude Details :</label>
              <input
                type="text"
                placeholder="Latitude"
                className="vi_0"
                onChange={(e) => setLatitude(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Longitude Details :</label>
              <input
                type="text"
                placeholder="Longitude"
                className="vi_0"
                onChange={(e) => setLogitude(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Facility Details :</label>
              <CKEditor
                editor={ClassicEditor}
                data={Facilities}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setFacilities(data);
                }}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Kitchentype Details :</label>
              <CKEditor
                editor={ClassicEditor}
                data={Kitchentype}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setKitchentype(data);
                }}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Opening Timings :</label>

              <CKEditor
                editor={ClassicEditor}
                data={OpeningTime}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setOpeningTime(data);
                }}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Placeknownfor Details :</label>
              <CKEditor
                editor={ClassicEditor}
                data={placeknownfor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setplaceknownfor(data);
                }}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Popular Dishes :</label>
              <CKEditor
                editor={ClassicEditor}
                data={PopularDish}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setPopularDish(data);
                }}
              />
            </div>
          </div>
          <div className="d-flex gap-3">
            <div className="col-lg-7  mt-4">
              <Table className="sdfsd-table-head" bordered>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Gallery?.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          <img
                            src={item?.imgUrl}
                            alt=""
                            style={{ width: "80px", height: "80px" }}
                          />
                        </td>
                        <td className="text-danger fs-5">
                          <MdDelete
                            style={{ cursor: "pointer" }}
                            onClick={() => removeItem2(i)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className=" col-lg-5 d-flex align-items-center">
              <div>
                <label className="fw-bold">Gallery Image :</label>
                <input
                  type="file"
                  className="vi_0"
                  onChange={(e) => {
                    onImageChange2(e);
                    setImage(e.target.files[0]);
                  }}
                />
              </div>
              <button className="pink-btn" onClick={AddGalleryImage}>
                Add
              </button>
            </div>
          </div>

          <div className="d-flex gap-3">
            <div className="col-lg-7  mt-4">
              <Table className="sdfsd-table-head" bordered>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Menu?.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          <img
                            src={item?.imgUrl}
                            alt=""
                            style={{ width: "80px", height: "80px" }}
                          />
                        </td>
                        <td className="text-danger fs-5">
                          <MdDelete
                            style={{ cursor: "pointer" }}
                            onClick={() => removeItem3(i)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className=" col-lg-5 d-flex align-items-center">
              <div>
                <label className="fw-bold">Menu Image :</label>
                <input
                  type="file"
                  className="vi_0"
                  onChange={(e) => {
                    onImageChange3(e);
                    setImage1(e.target.files[0]);
                  }}
                />
              </div>
              <button className="pink-btn" onClick={AddMenuImage}>
                Add
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={AddRestuarentCafe}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Activity */}
      <Modal show={show1} size="lg" onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            Edit Restuarant /Cafe
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Restaurant / Cafe :</label>
              <Form.Select
                className="vi_0"
                value={RestaturantType}
                onChange={(e) => setRestaturantType(e.target.value)}
              >
                {/* <option value={editdata?.restotype}>{editdata?.restotype}</option> */}
                <option value="Restaurant">Restaurant</option>
                <option value="Cafe">Cafe</option>
              </Form.Select>
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold"> Name Restaurant / Cafe :</label>
              <input
                type="text"
                value={RestaurantName}
                onChange={(e) => setRestaurantName(e.target.value)}
                className="vi_0"
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Mobile Number :</label>
              <input
                type="number"
                value={MobileN}
                className="vi_0"
                onChange={(e) => setMobileN(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Country :</label>
              <Form.Select
                value={Country1}
                onChange={(e) => setCountry1(e.target.value)}
                className="vi_0"
              >
                {CountryList?.map((country) => {
                  return (
                    <option value={`${country?.isoCode},${country?.name}`}>
                      {country?.name}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">State :</label>
              <Form.Select
                // value={State1}

                onChange={(e) => setState1(e.target.value)}
                className="vi_0"
              >
                {StateList?.map((state) => {
                  <option>{State1}</option>;
                  return (
                    <option value={`${state?.isoCode},${state?.name}`}>
                      {state?.name}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">City :</label>
              <Form.Select
                value={City1}
                onChange={(e) => setCity1(e.target.value)}
                className="vi_0"
              >
                {CityList?.map((city) => {
                  return <option value={city?.name}>{city?.name}</option>;
                })}
              </Form.Select>
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold"> Porparty Address :</label>
              <input
                type="text"
                value={Address}
                className="vi_0"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Property Location(MAP Link) :</label>
              <input
                type="Link"
                value={LocationLink}
                className="vi_0"
                onChange={(e) => setLocationLink(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Starting Price :</label>
              <input
                type="number"
                value={SatPrice}
                className="vi_0"
                onChange={(e) => setSatPrice(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Discount :</label>
              <input
                type="number"
                value={Discount}
                className="vi_0"
                onChange={(e) => setDiscount(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Latitude Details :</label>
              <input
                type="text"
                value={Latitude}
                className="vi_0"
                onChange={(e) => setLatitude(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Longitude Details :</label>
              <input
                type="text"
                value={Logitude}
                className="vi_0"
                onChange={(e) => setLogitude(e.target.value)}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Facility Details :</label>
              <CKEditor
                editor={ClassicEditor}
                data={Facilities}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setFacilities(data);
                }}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Kitchentype Details :</label>
              <CKEditor
                editor={ClassicEditor}
                data={Kitchentype}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setKitchentype(data);
                }}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Opening Timings :</label>

              <CKEditor
                editor={ClassicEditor}
                data={OpeningTime}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setOpeningTime(data);
                }}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Placeknownfor Details :</label>
              <CKEditor
                editor={ClassicEditor}
                data={placeknownfor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setplaceknownfor(data);
                }}
              />
            </div>
            <div className=" mt-2 col-lg-6">
              <label className="fw-bold">Popular Dishes :</label>
              <CKEditor
                editor={ClassicEditor}
                data={PopularDish}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setPopularDish(data);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="danger" onClick={EditRestoCafe}>
            Save{" "}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Gallery Model */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Gallery Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            ref={fileInputRef1}
            style={{ display: "none" }}
            onChange={(e) => {
              AddImageGallery(e.target.files[0]);
            }}
          />
          <Button onClick={openFile1}>Add</Button>
          <Table className="sdfsd-table-head text-center " bordered>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {DataView?.Gallery?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <img
                        src={`https://hayyyak.com/Restoandcafes/${item?.image1}`}
                        alt=""
                        style={{ width: "80px", height: "80px" }}
                      />
                    </td>
                    <td className="text-danger fs-5 d-flex gap-5 justify-content-center">
                      <MdDelete
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          DeleteGalleryImg(item?._id);
                        }}
                      />

                      <div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={(e) => {
                            EditGalleryInage(e.target.files[0]);
                          }}
                        />

                        <FaRegEdit
                          style={{ cursor: "pointer", color: "green" }}
                          onClick={() => {
                            openFile();
                            setEditGalleryImageID(item?._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Menu Model */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Menu Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            ref={fileInputRef1}
            style={{ display: "none" }}
            onChange={(e) => {
              AddImageMenu(e.target.files[0]);
            }}
          />
          <Button onClick={openFile1}>Add</Button>
          <Table className="sdfsd-table-head" bordered>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {DataView?.Menu?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <img
                        src={`https://hayyyak.com/Restoandcafes/${item?.image}`}
                        alt=""
                        style={{ width: "80px", height: "80px" }}
                      />
                    </td>
                    <td className="text-danger fs-5 d-flex gap-5 justify-content-center">
                      <MdDelete
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          DeleteMenuImg(item?._id);
                        }}
                      />
                      <div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={(e) => {
                            EditMenuImage(e.target.files[0]);
                          }}
                        />

                        <FaRegEdit
                          style={{ cursor: "pointer", color: "green" }}
                          onClick={() => {
                            openFile();
                            setEditGalleryImageID(item?._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
              src="./img/delete-btn.png"
              alt=""
            />
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose2}>
            Cancel
          </Button>
          <Button variant="danger" onClick={DeleteRestoCafe}>
            <FontAwesomeIcon icon={faCancel} className=" me-2" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default VendorRestaurantAndCafe;
