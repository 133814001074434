import axios from "axios";
import React from "react";
import { useState } from "react";
import { Button, Container, Form, InputGroup, Modal } from "react-bootstrap";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaGooglePlusG } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Vregister() {
  const formData = new FormData();
  const navigation = useNavigate();
  const notify = (data) => toast.success(data);
  const notify1 = (data) => toast.error(data);
  const notify2 = (data) => toast.warn(data);

  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [Mobile, setMobile] = useState();
  const [Email, setEmail] = useState("");
  const [SelectId, setSelectId] = useState("");
  const [IDcard, setIDcard] = useState("");
  const [GovtImage, setGovtImage] = useState("");
  const [Gst, setGst] = useState("");
  const [Password, setPassword] = useState("");
  const [Cpassword, setCpassword] = useState("");
  const [PasswordShow, setPasswordShow] = useState(false);
  const [confirmpasswordshow, setconfirmpasswordshow] = useState(false);

  const nameRegex = /^[a-zA-Z]+$/;
  const numberRegex = /^[6-9]\d{9}$/;
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  const passRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const register = async () => {
    if (!nameRegex.test(Fname)) {
      return notify2("Please Enter a Valid First Name");
    }
    if (!nameRegex.test(Lname)) {
      return notify2("Please Enter a Valid Last Name");
    }
    if (!numberRegex.test(Mobile)) {
      return notify2("Please Enter a Valid Number");
    }
    if (!emailRegex.test(Email)) {
      return notify2("Please Enter a Valid Email Address.");
    }
    if (!passRegex.test(Password)) {
      return notify2(
        "Please Enter a Valid Password.\nPassword must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character."
      );
    }
    if (!Fname || !Lname || !Mobile || !Email || !Password || !Cpassword) {
      notify2("Please fill all the fields");
    } else if (Password !== Cpassword) {
      notify2("Password & Confirm Password should be the same");
    } else {
      try {
        formData.append("Fname", Fname);
        formData.append("Lname", Lname);
        formData.append("Mobile", Mobile);
        formData.append("Email", Email);
        formData.append("Password", Password);
        formData.append("Cpassword", Cpassword);
        formData.append("SelectId", SelectId);
        formData.append("IDcard", IDcard);
        formData.append("GovtImage", GovtImage);
        formData.append("Gst", Gst);

        const config = {
          url: "/VendorSignup",
          method: "post",
          baseURL: "https://hayyyak.com/api",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios(config);

        if (response.status === 200) {
          notify("Registered Successfully");
          window.location.assign("/Vlogin");
        } else {
          notify1(response.data.error);
        }
      } catch (error) {
        console.log("error", error);
        notify1(error.response.data.error);
      }
    }
  };

  console.log("IDcard, GovtImage=>", IDcard, GovtImage);
  console.log("setFname", Fname);

  return (
    <div>
      <div
        style={{
          backgroundImage: 'url("./img/header-bg-img-3.jpg")',
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100%",
          width: "100%",
          padding: "68px",
        }}
      >
        <ToastContainer
          position="top-center"
          autoClose={30000}
          closeOnClick
          pauseOnHover
          draggable
        />
        <Container>
          <div className="login-bg">
            <div className="login mb-4">
              <h4>
                Create an <span className="text-danger">Account</span>
              </h4>
              <p className="text-secondary">Enter Your Details</p>
            </div>

            <div className="mb-4">
              <InputGroup className="mb-4">
                <Form.Control
                  className="login-input"
                  placeholder="First Name"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setFname(e.target.value)}
                />
              </InputGroup>

              <InputGroup className="mb-4">
                <Form.Control
                  className="login-input"
                  placeholder="Last Name"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setLname(e.target.value)}
                />
              </InputGroup>

              <InputGroup className="mb-4">
                <Form.Control
                  type="number"
                  className="login-input"
                  placeholder="Mobile"
                  aria-describedby="basic-addon1"
                  maxLength={10}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </InputGroup>

              <InputGroup className="mb-4">
                <Form.Control
                  type="email"
                  className="login-input"
                  placeholder="Email"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>

              {/* <Form.Select aria-label="Default select example" className="mb-2 login-input" onChange={(e) => setSelectId(e.target.value)}>
                <option>Selct Your Id</option>
                <option value="Passport">Passport</option>
                <option value="Adhar Card">Adhar Card</option>
                <option value="Ration Card">Ration Card</option>
                <option value="Voter ID">Voter ID</option>
              </Form.Select>

              <InputGroup className="mb-4" htmlFor="upload">
                <Form.Control
                  id="upload"
                  accept="image/*"
                  type="file"
                  className="login-input"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setIDcard(e.target.files[0])}
                />
              </InputGroup>

              <label className="fw-bold" htmlFor="upload">Choose goverment register DOC*</label>
              <InputGroup className="mb-4">
                <Form.Control
                  id="upload"
                  type="file"
                  accept="image/*"
                  className="login-input"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setGovtImage(e.target.files[0])}
                />
              </InputGroup>

              <InputGroup className="mb-4">
                <Form.Control
                  type="text"
                  placeholder="GST% Number"
                  className="login-input"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setGst(e.target.value)}
                />
              </InputGroup> */}

              <div>
                <InputGroup className="mb-4">
                  <Form.Control
                    type={PasswordShow ? "text" : "password"}
                    className="login-input"
                    placeholder="Password"
                    aria-describedby="basic-addon1"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {PasswordShow ? (
                    <button
                      onClick={() => setPasswordShow(!PasswordShow)}
                      className="passbtn"
                    >
                      <i class="fas fa-eye-slash"></i>
                    </button>
                  ) : (
                    <button
                      onClick={() => setPasswordShow(!PasswordShow)}
                      className="passbtn"
                    >
                      <i class="fas fa-eye"></i>
                    </button>
                  )}
                </InputGroup>
              </div>

              <div>
                <InputGroup className="mb-4">
                  <Form.Control
                    type={confirmpasswordshow ? "text" : "password"}
                    className="login-input"
                    placeholder="Confirm Password"
                    aria-describedby="basic-addon1"
                    onChange={(e) => setCpassword(e.target.value)}
                  />
                  {confirmpasswordshow ? (
                    <button
                      onClick={() =>
                        setconfirmpasswordshow(!confirmpasswordshow)
                      }
                      className="passbtn"
                    >
                      <i class="fas fa-eye-slash"></i>
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        setconfirmpasswordshow(!confirmpasswordshow)
                      }
                      className="passbtn"
                    >
                      <i class="fas fa-eye"></i>
                    </button>
                  )}
                </InputGroup>
              </div>
            </div>

            <div className="mb-4">
              <Button
                className="header-search"
                style={{ width: "100%" }}
                onClick={register}
              >
                Submit
              </Button>
            </div>

            <div className="text-center mb-4">
              <p>
                Already member ? |
                <a
                  href="/Vlogin"
                  style={{
                    color: "#d81d4a",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  Login
                </a>
              </p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Vregister;
