import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container, Form, InputGroup, Table } from 'react-bootstrap'
import { CiLocationOn } from 'react-icons/ci'
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";

export const TripPackageReview = () => {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Customer = JSON.parse(sessionStorage.getItem("customer"));


    const location = useLocation()
    const {
        item,
        Checkindates,
        CheckOutDates,
        Adults,
        Childrens,
        Petss,
    } = location.state
    console.log("itemmmm",
        item,
        Checkindates,
        CheckOutDates,
        Adults,
        Childrens,
        Petss,
    );
    // Actual Amount
    let AdultAmount = item?.tripcost * Adults
    let ChildAmount = item?.childrencost * Childrens
    let InfantAmount = item?.petscost * Petss
    // Discount Amount
    let AdultDiscAmount = item?.offerprice * Adults
    let ChildDiscAmount = item?.childrenoffer * Childrens
    let InfantDiscAmount = item?.petoffer * Petss
    // Discount %
    let AdultDiscount = item?.tripdiscount * Adults
    let ChildDiscount = item?.childrendiscount * Childrens
    let InfantDiscount = item?.petdiscount * Petss

    let TotalTravellers = (Adults + Childrens + Petss)
    let TotalDiscAmount = (AdultDiscAmount + ChildDiscAmount + InfantDiscAmount).toFixed(2)
    let TotalDiscount = (AdultDiscount + ChildDiscount + InfantDiscount)
    let TotalDiscountPrice = (AdultAmount + ChildAmount + InfantAmount - (TotalDiscAmount)).toFixed(2)

    const [TravelerEmalil, setTravelerEmalil] = useState()
    const [TravelerNumber, setTravelerNumber] = useState()
    // Booking Api
    const PackageBooking = async () => {
        if (!Customer) {
            return notify1("Please Login")
        }
        if (TravelersData.length <= 0) {
            return notify1("Please Enter All Travelers Details")
        }
        if (TravelersData.length !== TotalTravellers) {
            return notify1("Please Enter All Travelers Details")
        }
        if (!TravelerEmalil) {
            return notify1("Please Enter Email ID")
        }
        if (!TravelerNumber) {
            return notify1("Please Enter Phonenumber ID")
        }
        try {
            const config = {
                url: "admin/booktourpackage",
                method: "post",
                baseURL: "https://hayyyak.com/api/",
                headers: { "Content-Type": "application/json" },
                data: {
                    userId: Customer?._id,
                    packageId: item?._id,
                    checkindate: Checkindates,
                    CheckOutDates: CheckOutDates,
                    adultprice: AdultDiscAmount,
                    adultquantity: Adults,
                    childrenprice: ChildDiscAmount,
                    childrenquantity: Childrens,
                    petsprice: InfantDiscAmount,
                    petsquantity: Petss,
                    TravelersData: TravelersData,
                    useremail: TravelerEmalil,
                    userphoneno: TravelerNumber,
                },
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    notify(res.data.success);
                    window.location.assign("/trippackage");
                }
            });
        } catch (error) {
            console.log(error);
            notify1(error.response.data.error);
        }
    };

    const [TravelersData, setTravelersData] = useState([]);
    const handleInputChange = (index, field, value) => {
        setTravelersData((prevData) => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], [field]: value };
            return newData;
        })
    }


    const travelerInputs = [];
    for (let i = 1; i <= TotalTravellers; i++) {
        travelerInputs.push(
            <div key={i}>
                <label className='fw-bold'>{`Traveller ${i}:`}</label>
                <div className='mb-2 row'>
                    <div className='col-lg-5'>
                        <label htmlFor={`firstName${i}`}>First Name</label>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='login-input'
                                type='text'
                                aria-label={`First Name ${i}`}
                                aria-describedby="basic-addon1"
                                onChange={(e) => handleInputChange(i - 1, 'TravellerFirstName', e.target.value)}
                            />
                        </InputGroup>
                    </div>

                    <div className="col-lg-5">
                        <label htmlFor={`lastName${i}`}>Last Name</label>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='login-input'
                                type='text'
                                aria-label={`Last Name ${i}`}
                                aria-describedby="basic-addon1"
                                onChange={(e) => handleInputChange(i - 1, 'TravellerLastName', e.target.value)}
                            />
                        </InputGroup>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={30000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container>
                <div className="row my-4 justify-content-between align-items-center trip-review-heading">
                    <h3 className=' col-lg-4'>Review Your <span className='text-danger'>Booking</span></h3>
                    {!Customer ? (<p style={{ color: '#008cff', fontSize: "14px" }} className='col-lg-6'>Please<a href="/login" className='text-danger text-decoration-none'>"Login"</a> before entering the traveller details </p>
                    ) : (null)}
                </div>
                <div className="row mb-3">
                    <div className="col-lg-8 ">
                        <div className="hotel-bok mb-3" style={{ height: '238px' }}>
                            <div className="row">
                                <div className="col-lg-4 mb-2">
                                    <img
                                        src={`https://hayyyak.com/Packages/${item?.GalleryImage?.[0]?.image}`}
                                        alt="" style={{ width: '200px', height: '200px', imageRendering: 'pixelated' }}
                                        className='tripPackage-review-image'
                                        />
                                </div>
                                <div className="col-lg-7 ">
                                    <p className='fw-bold fs-4 text-danger'>{item?.tripname}</p>
                                    <p className='text-secondary mb-2'><CiLocationOn className="text-danger me-2" />{item?.country} {item?.state} {item?.city}</p>
                                    <p className='text-secondary mb-2'>{item?.tripduration} day / {item?.tripnight} Nights</p>
                                    <hr />
                                    <div className="row gap-3">
                                        <div className="col-lg-5">
                                            <p className='text-secondary'>Start Date</p>
                                            <p className='fw-bold'>{Checkindates}</p>
                                        </div>
                                        <div className="col-lg-5">
                                            <p className='text-secondary'>End Date</p>
                                            <p className='fw-bold'>{CheckOutDates}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <h2 className='mb-4'>Traveller <span className='text-danger'>Details</span></h2>
                            <div>
                                {travelerInputs}
                            </div>

                            <div className='mb-2 row'>
                                <label>Contact Details</label>
                                <div className='col-lg-5'>
                                    <InputGroup className='mb-3'>
                                        <Form.Control
                                            className='login-input'
                                            type='email'
                                            placeholder="Enter Your Email"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => setTravelerEmalil(e.target.value)}
                                        />
                                    </InputGroup>
                                </div>

                                <div className="col-lg-5">
                                    <InputGroup className='mb-3' >
                                        <Form.Control
                                            className='login-input'
                                            type='text'
                                            placeholder="Enter Mobile Number"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => setTravelerNumber(e.target.value)}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-4">
                        <div style={{
                            border: '1px solid rgb(221, 221, 221)',
                            borderRadius: '12px',
                            padding: '24px',
                            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 6px 16px',
                        }}>
                            <p className='fw-semibold fs-5 mb-2'>Price Breakup</p>

                            <Table>
                                <tbody>
                                    <tr>
                                        <td className='fw-semibold'>Adult x {Adults} </td>
                                        <td className='fw-medium text-secondary'>₹{AdultAmount} <span style={{ fontSize: '12px' }}>({AdultDiscount}%)</span></td>
                                    </tr>
                                    <tr>
                                        <td className='fw-semibold'>Children x {Childrens} </td>
                                        <td className='fw-medium text-secondary'>₹{ChildAmount} <span style={{ fontSize: '12px' }}>({ChildDiscount}%)</span></td>
                                    </tr>
                                    <tr>
                                        <td className='fw-semibold'>Infants x {Petss} </td>
                                        <td className='fw-medium text-secondary'>₹{InfantAmount} <span style={{ fontSize: '12px' }}>({InfantDiscount}%)</span></td>
                                    </tr>
                                    <tr>
                                        <td className='fw-semibold'>Discount%</td>
                                        <td className='fw-medium text-secondary'>-₹{TotalDiscountPrice} <span style={{ fontSize: '12px' }}>({TotalDiscount}%)</span></td>
                                    </tr>
                                    {/* <tr>
                                        <td className='fw-semibold'>Taxes</td>
                                        <td className='fw-medium text-secondary'>₹1000</td>
                                    </tr> */}
                                </tbody>
                            </Table>
                            <div className="vasghd_0 p-1">
                                <p className='fw-bold fs-5'>Total Amount </p>
                                <p className='fw-medium text-danger fs-5'>₹{TotalDiscAmount}</p>
                            </div>
                            <div>
                                <button onClick={PackageBooking} className="header-search" style={{ width: '100%', height: '44px' }}>Pay Now</button>
                            </div>
                        </div>
                    </div>
                </div>


            </Container>
        </div>
    )
}
