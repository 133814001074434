import React, { useEffect, useRef, useState } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import axios from 'axios'
import parse from "html-react-parser"
import { Country, State, City } from "country-state-city";
import moment from 'moment/moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { AlignVerticalBottomSharp } from '@mui/icons-material'

function VendorAddActivity() {

    const ActivityVendor = JSON.parse(sessionStorage.getItem("activityvendor"))

    // input file access
    const fileInputRef = useRef(null);
    const [amid, setamid] = useState("");

    const handleIconClick = (id) => {
        setamid(id)
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };


    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);

    const [Category, setCategory] = useState("")
    const [Overview, setOverview] = useState("")

    const [Tours, setTours] = useState("")

    const [Country1, setCountry1] = useState("")
    const [State1, setState1] = useState("")
    const [City1, setCity1] = useState("")
    const [Location, setLocation] = useState("")
    // const [Day, setDay] = useState("")
    // const [Night, setNight] = useState("")
    const [Children, setChildren] = useState("")
    const [ChildrenDiscount, setChildrenDiscount] = useState("")
    const [AdultDiscount, setAdultDiscount] = useState("")
    const [Price, setPrice] = useState("")
    const [tourschedule, settourschedule] = useState([]);
    const CountryList = Country.getAllCountries();
    const StateList = State.getStatesOfCountry(Country1);
    const CityList = City.getCitiesOfState(Country1, State1);
    //  new changes
    const [Terms, setTerms] = useState("")
    const [Suggestion, setSuggestion] = useState("")
    const [Policy, setPolicy] = useState("")
    const [Timing, setTiming] = useState("")
    const [bigImage, setbigImage] = useState({})

    // Add Gellery Images
    const [imageURL2, setimageURL2] = useState(null);
    const [Image, setImage] = useState("");
    const [galleryImages, setGalleryImages] = useState([]);
    const onImageChange2 = (event) => {
        if (event.target.files && event.target.files[0]) {
            setimageURL2(URL.createObjectURL(event.target.files[0]))
        }
    }
    const AddGalleryImage = () => {
        if (!Image) {
            return alert("Please, Select Image")
        }
        const imageExists = galleryImages.find((img) => img?.imgUrl === Image);
        if (imageExists) {
            return alert("Image already exists in the gallery.");
        }
        const obj = {
            image: Image,
            imgUrl: imageURL2,
        }
        setGalleryImages([...galleryImages, obj])
    }
    const removeImage = (val) => {
        const updatedGallery = galleryImages.filter((item, index) => index !== val)
        setGalleryImages(updatedGallery)
    }

    // Add Location
    const Addlocation = () => {
        let childrenofferprice = Children - (Children * ChildrenDiscount) / 100;
        let AdultOfferPrice = Price - (Price * AdultDiscount) / 100;
        const scheduleExists = tourschedule?.filter(
            (ele) =>
                ele?.Country === Country1 &&
                ele?.State === State1 &&
                ele?.City === City1 &&
                ele?.Location === Location &&
                // ele?.Day === Day &&
                // ele?.Night === Night &&
                ele?.Timing === Timing &&
                ele.Children === Children &&
                ele?.ChildrenDiscount === ChildrenDiscount &&
                ele?.Price === Price &&
                ele?.AdultDiscount === AdultDiscount
        );

        if (!scheduleExists) {
            return alert("Details is  already exists.");
        }

        const obj = {
            Country: Country1,
            State: State1,
            City: City1,
            Location: Location,
            // Day: Day,
            // Night: Night,
            Timing: Timing,
            Children: Children,
            ChildrenDiscount: ChildrenDiscount,
            Childrentotal: childrenofferprice,
            Price: Price,
            AdultDiscount: AdultDiscount,
            Adulttotal: AdultOfferPrice,
        };
        settourschedule([...tourschedule, obj]);
    };
    const removeschedule = (val) => {
        const updatedGallery = tourschedule.filter((item, index) => index !== val);
        settourschedule(updatedGallery);
    };

    // Add data
    const AddActivity = async () => {
        if (!Category) {
            return alert("Please Select Category")
        }
        if (!Overview) {
            return alert("Please Enter Overview")
        }
        if (!Tours) {
            return alert("Please Enter Activity Highlights")
        }
        if (!Terms) {
            return alert("Please Enter Terms and Condtions")
        }
        if (!Suggestion) {
            return alert("Please Enter Suggestion")
        }
        if (!Policy) {
            return alert("Please Enter Cancelletion Policy")
        }
        try {
            const config = {
                url: "/addactivity",
                baseURL: "https://hayyyak.com/api/admin/activity",
                method: "post",
                headers: { "content-type": "application/json" },
                data: {
                    vendorstatus: "pending",
                    Category: Category,
                    Overview: Overview,
                    Tours: Tours,
                    addlocation: tourschedule,
                    actiitiyid: ActivityVendor?._id,
                    Terms: Terms,
                    Suggestion: Suggestion,
                    Policy: Policy,
                }
            }
            await axios(config).then(async (res) => {
                if (res.status === 200) {
                    for (let i = 0; i < galleryImages.length; i++) {
                        const config = {
                            url: "/admin/activity/AddGallery",
                            baseURL: "https://hayyyak.com/api",
                            headers: { "Content-Type": "multipart/form-data" },
                            method: "put",
                            data: {
                                productId: res.data.success?._id,
                                image: galleryImages[i]?.image,
                            },
                        };
                        await axios(config)
                    }
                    alert("Package Added Successfully");
                    GetActivityDetail()
                    handleClose()
                }
            })
        } catch (error) {
            alert(error.response.data.error)
        }
    }

    // Getting data from activity category
    const [GetActivitycat, setGetActivitycat] = useState([])
    const GetActivityCategory = () => {
        axios
            .get("https://hayyyak.com/api/admin/activity/getactivitycategory")
            .then(function (response) {
                console.log(response.data);
                setGetActivitycat(response.data.success)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    // Get activity
    const [View, setView] = useState({})
    const [GetActivity, setGetActivity] = useState([])
    const [Galleryview, setGalleryview] = useState([])
    const GetActivityDetail = () => {
        axios
            .get("https://hayyyak.com/api/admin/activity/getactivitydetail")
            .then(function (response) {
                console.log(response.data);
                setGetActivity(response.data.getActivity)
                setnochangedata(response.data.getActivity)
                setPagination(response.data.getActivity)

            })
            .catch(function (error) {
                console.log(error);
            })
    }

    console.log("GetActivity", GetActivity)

    // Edit Activity
    const [editdata, seteditdata] = useState("")
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (item) => { setShow1(true); seteditdata(item) }

    const EditActivity = async () => {
        try {
            const config = {
                url: "/editactivity/" + editdata?._id,
                baseURL: "https://hayyyak.com/api/admin/activity",
                method: "put",
                headers: { "content-type": "application/json" },
                data: {
                    Category: Category,
                    Overview: Overview,
                    Tours: Tours,
                    addlocation: tourschedule,
                    Terms: Terms,
                    Suggestion: Suggestion,
                    Policy: Policy,
                }
            }
            axios(config).then((res) => {
                if (res.status === 200) {
                    alert(res.data.success)
                    GetActivityDetail()
                    handleClose1()
                }
            })
        } catch (error) {
            console.log(error)
            alert(error.response.data.error)
        }
    }


    // Delete
    const [Data, setdata] = useState({})
    const deleteClose = () => setShow2(false)
    const deleteShow = (item) => { setShow2(true); setdata(item) }

    const DeleteActivity = async () => {
        try {
            const config = {
                url: '/deleteactivity/' + Data?._id,
                baseURL: 'https://hayyyak.com/api/admin/activity',
                method: 'DELETE',
                headers: { "content-type": "application/json" },
            }
            await axios(config).then((res) => {
                if (res.status === 200) {
                    alert(res.data.success);
                    GetActivityDetail();
                    deleteClose()
                }
            })

        } catch (error) {
            alert(error.response.data.error)
        }
    }

    useEffect(() => {
        GetActivityCategory()
        GetActivityDetail()
    }, [])

    // For location editing
    const [alg, setalg] = useState(0);
    const [Editing, setEditing] = useState(false);

    // Location Editing
    const [Data2, setData2] = useState("")
    const UpdateLocation = async () => {
        let childrenofferprice = Children - (Children * ChildrenDiscount) / 100;
        let AdultOfferPrice = Price - (Price * AdultDiscount) / 100
        try {
            const config = {
                url: "/admin/activity/updatelocation/" + Data2?._id,
                method: "put",
                baseURL: "https://hayyyak.com/api",
                header: { "content-type": "application/json" },
                data: {
                    schId: View?._id,
                    Country: Country1,
                    State: State1,
                    City: City1,
                    Location: Location,
                    // Day: Day,
                    // Night: Night,
                    Timing: Timing,
                    Children: Children,
                    ChildrenDiscount: ChildrenDiscount,
                    Childrentotal: childrenofferprice,
                    Price: Price,
                    AdultDiscount: AdultDiscount,
                    Adulttotal: AdultOfferPrice,
                },
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    setalg(0)
                    alert("Updated Successfully");
                    setView(res.data.success);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteLocation = (id) => {
        const confirmChek = window.confirm("Are you sure you want to delete this item")
        if (confirmChek) {
            DeleteLocation(id)
        }
    }
    // Location Delete
    const [Data3, setData3] = useState("");
    const DeleteLocation = async (id) => {
        try {
            const config = {
                url: "/admin/activity/deletelocation/" + id,
                method: "delete",
                baseURL: "https://hayyyak.com/api",
                header: { "content-type": "application/json" },
                data: {
                    tourid: View?._id,
                },
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    alert("Successfully Delete");
                    GetActivityDetail();
                    setView(res.data.success);
                }
            });
        } catch (error) {
            console.log(error);
            alert(error.response.data.error);
        }
    };

    // Update Galley
    const formData = new FormData();
    const UpdateGalleryImages = async (img) => {
        formData.append("image", img)
        formData.append("activityid", Galleryview?._id)
        formData.append("galleryid", amid);
        try {
            const config = {
                url: "/admin/activity/updategallery",
                method: "put",
                baseURL: "https://hayyyak.com/api",
                headers: { "Content-Type": "multipart/form-data" }, // Corrected the typo in headers
                data: formData,
            };
            const response = await axios(config);
            if (response.status === 200) {
                setGalleryview(response.data.success)
                alert("Updated Successfully");
                GetActivityDetail()
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteGalleryimage = (id) => {
        const confirmChek = window.confirm("Are you sure you want to delete this item")
        if (confirmChek) {
            DeleteGalleryimage(id)
        }
    }
    //Delete gallery Image
    const DeleteGalleryimage = async (id) => {
        try {
            const config = {
                url: "/admin/activity/deletegallery/" + id,
                method: "delete",
                baseURL: "https://hayyyak.com/api",
                header: { "content-type": "application/json" },
                data: {
                    productId: Galleryview?._id,
                },
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    alert("Successfully Delete");
                    setGalleryview(res.data.success);
                    GetActivityDetail()
                }
            });
        } catch (error) {
            console.log(error);
            alert(error.response.data.error);
        }
    };

    // Date Filter
    const [noschangedata, setnochangedata] = useState([]);
    const [fromd, setfromd] = useState("");
    const [tod, settod] = useState("");
    const searchDate = () => {
        if (!fromd) return alert("Please select from date");
        if (!tod) return alert("Please select to date");

        let std = fromd?.split("-");
        let entd = tod?.split("-");
        let startD = parseInt(std?.join(""));
        let endD = parseInt(entd?.join(""));
        let abc = noschangedata?.filter((ele) => {
            let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
            let amd = parseInt(abd?.join(""));
            return startD <= amd && endD >= amd;
        });
        setPagination(abc);
        setGetActivity(abc);
    };

    // Search
    const [SearchItem, setSearchItem] = useState("");

    //Pagination
    const [pagination, setPagination] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 5;
    const filteredPagination = pagination?.filter((ele) => ele.actiitiyid === ActivityVendor?._id);
    const pagesVisited = pageNumber * usersPerPage;
    const pageCount = Math.ceil(filteredPagination.length / usersPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    return (
        <div>
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2'>
                    <p className="text-danger fs-4 fw-semibold">Activity :</p>
                    <button className='pink-btn' onClick={handleShow}>Add Activity</button>
                </div>

                <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                    <div className="col-lg-2">
                        <label>From :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => setfromd(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <label>To :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => settod(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <button className="pink-btn" onClick={searchDate}>Submit</button>
                    </div>

                    <div className="input-group col-lg-4" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-describedby="basic-addon1"
                            onChange={(e) => setSearchItem(e.target.value)}
                        />
                    </div>

                </div>
                <div >
                    <Table responsive bordered className='admin-table-head' >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Category</th>
                                <th style={{ width: '400px' }}>OverView</th>
                                <th>Gallery</th>
                                <th>All Locations</th>
                                <th style={{ width: '400px' }}>Activity Highlights</th>
                                <th style={{ width: '400px' }}>Terms and Condtions</th>
                                <th style={{ width: '400px' }}>Suggestions</th>
                                <th style={{ width: '400px' }}>Privacy Policy</th>
                                <th>Register Date</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* slice(pagesVisited, pagesVisited + usersPerPage)?. */}
                            {GetActivity?.filter((ele) => ele.actiitiyid === ActivityVendor?._id)?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item, i) => {
                                if (SearchItem === "" || item?.activitycategories?.Title?.toLowerCase()?.includes?.(SearchItem?.toLowerCase()))
                                    return (<>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{parse(`<div>${item?.activitycategories?.Title}</div>`)}</td>
                                            <td>{parse(`<div>${item?.Overview}</div>`)}</td>
                                            <td>
                                                <img
                                                    src={`https://hayyyak.com/ActivityCategory/${item?.GalleryImages?.[0]?.image}`}
                                                    alt=""
                                                    style={{
                                                        width: "150px",
                                                        height: "150px",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                                <p
                                                    className="Ldhfh_0"
                                                    onClick={() => {
                                                        handleShow4();
                                                        setGalleryview(item);
                                                    }}
                                                >
                                                    View More
                                                </p>
                                            </td>
                                            <td>
                                                <div>
                                                    <button className='pink-btn' onClick={() => {
                                                        handleShow3();
                                                        setView(item);
                                                    }}>View Location</button>
                                                </div>
                                            </td>
                                            <td className='text-start'>
                                                {parse(`<div>${item?.Tours}</div>`)}
                                            </td>
                                            <td className='text-start'>
                                                {parse(`<div>${item?.Terms}</div>`)}
                                            </td>
                                            <td className='text-start'>
                                                {parse(`<div>${item?.Suggestion}</div>`)}
                                            </td>
                                            <td className='text-start'>
                                                {parse(`<div>${item?.Policy}</div>`)}
                                            </td>
                                            <td className='text-start'>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                                            <td>
                                                <div>
                                                    {item?.vendorstatus === "pending" ? (<>
                                                        <span className='unblock-user' style={{ backgroundColor: '#dba709' }}>Pending</span>
                                                    </>) : (<>
                                                        <span className='unblock-user'>Approved</span>
                                                    </>)}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                    <BiSolidEdit className='text-primary' onClick={() => handleShow1(item)} />
                                                    <MdDelete className='text-danger' onClick={() => deleteShow(item)} />
                                                </div>
                                            </td>
                                        </tr>
                                    </>)
                            })}
                        </tbody>
                    </Table>
                </div>

                <div className="my-3">
                    <Stack spacing={2}>
                        <Pagination
                            count={pageCount}
                            onChange={(event, value) => {
                                changePage(value - 1)
                            }}
                            color="primary"
                        />
                    </Stack>
                </div>
            </Container>

            {/* Add Activity Modal */}
            <Modal size='lg' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add Activity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-2'>
                        <label className='fw-bold'>Select Category :</label>
                        <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setCategory(e.target.value)}>
                            <option>Select Category</option>
                            {GetActivitycat?.filter((ele) => ele.actiitiyid === ActivityVendor?._id)?.map((item) => {
                                return (
                                    <option value={item?._id}>{parse(`<div>${item?.Title}</div>`)}</option>
                                )
                            })}
                        </Form.Select>
                    </div>
                    <div className="mt-2">
                        <label className='fw-bold'>Overview :</label>
                        <CKEditor editor={ClassicEditor}
                            data={Overview}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setOverview(data);
                            }}
                        />
                    </div>

                    <p className='mt-2'>Add Multiple <span className='text-danger'>Location :</span></p>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mt-2">
                                <label className='fw-bold'>Country :</label>
                                <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setCountry1(e.target.value)}>
                                    <option>Select Country</option>
                                    {CountryList.map((Country) => (
                                        <option value={Country.isoCode}>
                                            {Country.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mt-2">
                                <label className='fw-bold'>State :</label>
                                <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setState1(e.target.value)}>
                                    <option>Select State</option>
                                    {StateList.map((state) => (
                                        <option value={state.isoCode}>
                                            {state.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className=" mt-2">
                                <label className='fw-bold'>City :</label>
                                <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setCity1(e.target.value)}>
                                    <option>Select City</option>
                                    {CityList.map((city) => (
                                        <option value={city.name}>
                                            {city.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className=" mt-2">
                                <label className='fw-bold'>Location :</label>
                                <input
                                    type="text"
                                    placeholder='area, street... '
                                    className="vi_0"
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                            </div>
                        </div>

                        {/* <div className=" mt-2 col-lg-6">
                            <label className='fw-bold'>Days :</label>
                            <input
                                type="text"
                                placeholder="Days"
                                className="vi_0"
                                onChange={(e) => setDay(e.target.value)}
                            />
                        </div>
                        <div className=" mt-2 col-lg-6">
                            <label className='fw-bold'>Nights :</label>
                            <input
                                type="text"
                                placeholder="Nights"
                                className="vi_0"
                                onChange={(e) => setNight(e.target.value)}
                            />
                        </div> */}
                        <div className=" mt-2 col-lg-6">
                            <label className='fw-bold'>Activity Duration :</label>
                            <input
                                type="text"
                                placeholder="Time"
                                className="vi_0"
                                onChange={(e) => setTiming(e.target.value)}
                            />
                        </div>

                        <div className="col-lg-6">
                            <div className=" mt-2">
                                <label className='fw-bold'>Adult Price :</label>
                                <input
                                    type="text"
                                    placeholder='price'
                                    className="vi_0"
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className=" mt-2">
                                <label className='fw-bold'>Adult Discount% :</label>
                                <input
                                    type="text"
                                    placeholder='Discount%'
                                    className="vi_0"
                                    onChange={(e) => setAdultDiscount(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className=" mt-2">
                                <label className='fw-bold'>Children Price :</label>
                                <input
                                    type="text"
                                    placeholder='price'
                                    className="vi_0"
                                    onChange={(e) => setChildren(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className=" mt-2">
                                <label className='fw-bold'>Children Discount% :</label>
                                <input
                                    type="text"
                                    placeholder='Discount%'
                                    className="vi_0"
                                    onChange={(e) => setChildrenDiscount(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='mt-3 d-flex justify-content-center'>
                        <button className="pink-btn" onClick={Addlocation}>Add</button>
                    </div>

                    <Table responsive bordered className='mt-2 admin-table-head'>
                        <thead>
                            <tr>
                                <th> S.No</th>
                                <th> Country</th>
                                <th> State</th>
                                <th> City</th>
                                <th> Location</th>
                                {/* <th> Days</th>
                                <th> Nights</th> */}
                                <th> Duration</th>
                                <th> Adult Price</th>
                                <th> Adult Discount%</th>
                                <th> Adult Total</th>
                                <th> Children Price</th>
                                <th> Children Discount%</th>
                                <th> Children Total</th>
                                <th> Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tourschedule?.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{item?.Country}</td>
                                        <td>{item?.State}</td>
                                        <td>{item?.City}</td>
                                        <td>{item?.Location}</td>
                                        <td>{item?.Day}</td>
                                        <td>{item?.Night}</td>
                                        <td>{item?.Timing}</td>
                                        <td>₹{item?.Price}</td>
                                        <td>{item?.AdultDiscount}%</td>
                                        <td>₹{item?.Adulttotal}</td>
                                        <td>₹{item?.Children}</td>
                                        <td>{item?.ChildrenDiscount}%</td>
                                        <td>₹{item?.Childrentotal}</td>
                                        <td>
                                            <div
                                                className="d-flex gap-2 fs-4"
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {/* <BiSolidEdit className="text-primary" /> */}
                                                <MdDelete
                                                    onClick={() =>
                                                        removeschedule(i)
                                                    }
                                                    className="text-danger"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <div className="row mb-3">
                        <div className="col-lg-6 mt-2">
                            <label className='fw-bold'>Activity Highlights :</label>
                            <CKEditor editor={ClassicEditor}
                                data={Tours}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setTours(data);
                                }}
                            />
                        </div>
                        <div className="col-lg-6 mt-2">
                            <label className='fw-bold'>Terms and Conditions :</label>
                            <CKEditor editor={ClassicEditor}
                                data={Terms}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setTerms(data);
                                }}
                            />
                        </div>

                        <div className="col-lg-6 mt-2">
                            <label className='fw-bold'>Suggestions :</label>
                            <CKEditor editor={ClassicEditor}
                                data={Suggestion}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setSuggestion(data);
                                }}
                            />
                        </div>
                        <div className="col-lg-6 mt-2">
                            <label className='fw-bold'>Cancelletion Policy:</label>
                            <CKEditor editor={ClassicEditor}
                                data={Policy}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setPolicy(data);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mb-3 justify-content-between">
                        <div className="col-lg-5 mt-2">
                            <label className='fw-bold' htmlFor='upload'>Gallery Images :</label>
                            <div className='d-flex gap-4 align-items-center'>
                                <input
                                    accept='image/*'
                                    id='upload'
                                    type="file"
                                    className="vi_0"
                                    onChange={(e) => {
                                        onImageChange2(e);
                                        setImage(e.target.files[0])
                                    }}
                                />
                                <button onClick={AddGalleryImage} className='pink-btn' style={{ height: '40px' }}>Add</button>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-2">
                            <Table bordered className='sdfsd-table-head'>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Image</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {galleryImages?.map((item, i) => (<>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td><img src={item?.imgUrl} alt="" style={{ width: '50px', height: '50px' }} /></td>
                                            <td><MdDelete onClick={() => removeImage(i)} className='text-danger fs-4' /></td>
                                        </tr>
                                    </>))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body >
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AddActivity}>
                        Add Activity
                    </Button>
                </Modal.Footer>
            </Modal >

            {/* Edit Activity */}
            < Modal show={show1} onHide={handleClose1} >
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Activity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-2'>
                        <label className='fw-bold'>Select Category :</label>
                        <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setCategory(e.target.value)}>
                            <option>Select Category</option>
                            {GetActivitycat?.filter((ele) => ele.actiitiyid === ActivityVendor?._id)?.map((item) => {
                                return (
                                    <option value={item?._id}>{parse(`<div>${item?.Title}</div>`)}</option>
                                )
                            })}
                        </Form.Select>
                    </div>
                    <div className="mt-2">
                        <label className='fw-bold'>Overview :</label>
                        <CKEditor editor={ClassicEditor}
                            data={editdata?.Overview}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setOverview(data);
                            }}
                        />
                    </div>
                    <div className=" mt-2">
                        <label className='fw-bold'>Activity Highlights :</label>
                        <CKEditor editor={ClassicEditor}
                            data={editdata?.Tours}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setTours(data);
                            }}
                        />
                    </div>
                    <div className="mt-2">
                        <label className='fw-bold'>Terms and Conditions :</label>
                        <CKEditor editor={ClassicEditor}
                            data={editdata?.Terms}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setTerms(data);
                            }}
                        />
                    </div>
                    <div className="mt-2">
                        <label className='fw-bold'>Suggestions :</label>
                        <CKEditor editor={ClassicEditor}
                            data={editdata?.Suggestion}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setSuggestion(data);
                            }}
                        />
                    </div>
                    <div className="mt-2">
                        <label className='fw-bold'>Cancelletion Policy  :</label>
                        <CKEditor editor={ClassicEditor}
                            data={editdata?.Policy}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setPolicy(data);
                            }}
                        />
                    </div>
                </Modal.Body >
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={EditActivity}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal >

            {/* Delete Modal */}
            <Modal
                show={show2}
                onHide={deleteClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={deleteClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => DeleteActivity()}><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>

            {/* Gallery Image Model */}
            <Modal show={show4} onHide={handleClose4} className="gallery_mdl">
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">Gallery Images</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table bordered className="sdfsd-table-head">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Image</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Galleryview?.GalleryImages?.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                            <img
                                                style={{
                                                    width: "100px",
                                                    height: '100px',
                                                    cursor: "pointer",
                                                    imageRendering: "pixelated",
                                                }}
                                                src={`https://hayyyak.com/ActivityCategory/${item?.image}`}
                                                alt=""
                                                onClick={() => {
                                                    handleShow5()
                                                    setbigImage(item)
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    style={{ display: "none" }}
                                                    onChange={(e) => {
                                                        UpdateGalleryImages(e.target.files[0], item._id);
                                                    }}
                                                />
                                                <BiSolidEdit
                                                    className="text-primary fs-4"
                                                    type="file"
                                                    onClick={() => handleIconClick(item?._id)}
                                                />
                                                <MdDelete className='text-danger' onClick={() => handleDeleteGalleryimage(item?._id)} />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose4}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Gallery Big Image Modal */}
            <Modal show={show5} onHide={handleClose5} className="mb-0" size="lg">
                <Modal.Body className="p-0">

                    <img
                        src={`https://hayyyak.com/ActivityCategory/${bigImage?.image}`}
                        alt=""
                        style={{
                            width: "-webkit-fill-available",
                            height: '500px',
                            imageRendering: "pixelated",
                        }}
                        onClick={handleClose5}
                    />

                </Modal.Body>
            </Modal>

            {/* View Location */}
            <Modal size='lg' show={show3} onHide={handleClose} >
                <Modal.Header>
                    <Modal.Title>All <span className='text-danger'>Location</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive bordered className='admin-table-head'>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Country</th>
                                <th>State</th>
                                <th>City</th>
                                {/* <th>Days</th>
                                <th>Nights</th> */}
                                <th>Duration</th>
                                <th>Location</th>
                                <th>Adult Price</th>
                                <th>Adult Discount%</th>
                                <th>Adult Total</th>
                                <th>Children Price</th>
                                <th>Children Discount%</th>
                                <th>Children Total</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {View?.addlocation?.map((item, i) => {
                                return (<>
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                            {alg == i + 1 ? (<input
                                                type='text'
                                                className='form-control location-input'
                                                value={Country1}
                                                onChange={(e) => setCountry1(e.target.value)}
                                            />) : (item?.Country)}
                                        </td>
                                        <td>
                                            {alg == i + 1 ? (<input
                                                type='text'
                                                className='form-control location-input'
                                                value={State1}
                                                onChange={(e) => setState1(e.target.value)}
                                            />) : (item?.State)}
                                        </td>
                                        <td>
                                            {alg == i + 1 ? (<input
                                                type='text'
                                                className='form-control location-input'
                                                value={City1}
                                                onChange={(e) => setCity1(e.target.value)}
                                            />) : (item?.City)}
                                        </td>
                                        {/* <td>
                                            {alg == i + 1 ? (<input
                                                type='text'
                                                className='form-control location-input'
                                                value={Day}
                                                onChange={(e) => setDay(e.target.value)}
                                            />) : (item?.Day)}
                                        </td> */}
                                        {/* <td>
                                            {alg == i + 1 ? (<input
                                                type='text'
                                                className='form-control location-input'
                                                value={Night}
                                                onChange={(e) => setNight(e.target.value)}
                                            />) : (item?.Night)}
                                        </td> */}
                                        <td>
                                            {alg == i + 1 ? (<input
                                                type='text'
                                                className='form-control location-input'
                                                value={Timing}
                                                onChange={(e) => setTiming(e.target.value)}
                                            />) : (item?.Timing)}
                                        </td>
                                        <td>
                                            {alg == i + 1 ? (<input
                                                type='text'
                                                className='form-control location-input'
                                                value={Location}
                                                onChange={(e) => setLocation(e.target.value)}
                                            />) : (item?.Location)}
                                        </td>
                                        <td>
                                            {alg == i + 1 ? (<input
                                                type='text'
                                                className='form-control location-input'
                                                value={Price}
                                                onChange={(e) => setPrice(e.target.value)}
                                            />) : (item?.Price)}
                                        </td>
                                        <td>
                                            {alg == i + 1 ? (<input
                                                type='text'
                                                className='form-control location-input'
                                                value={AdultDiscount}
                                                onChange={(e) => setAdultDiscount(e.target.value)}
                                            />) : (item?.AdultDiscount)}
                                        </td>
                                        <td>
                                            ₹{item?.Adulttotal}
                                        </td>
                                        <td>
                                            {alg == i + 1 ? (<input
                                                type='text'
                                                className='form-control location-input'
                                                value={Children}
                                                onChange={(e) => setChildren(e.target.value)}
                                            />) : (item?.Children)}
                                        </td>
                                        <td>
                                            {alg == i + 1 ? (<input
                                                type='text'
                                                className='form-control location-input'
                                                value={ChildrenDiscount}
                                                onChange={(e) => setChildrenDiscount(e.target.value)}
                                            />) : (item?.ChildrenDiscount)}
                                        </td>
                                        <td>
                                            ₹{item?.Childrentotal}
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>

                                                {alg == i + 1 ? (<button
                                                    onClick={() => {
                                                        UpdateLocation();
                                                    }} className='savee-btn'>Save</button>) : (
                                                    <BiSolidEdit className='text-primary' onClick={() => {
                                                        setCountry1(item?.Country)
                                                        setState1(item?.State)
                                                        setCity1(item?.City)
                                                        // setDay(item?.Day)
                                                        // setNight(item?.Night)
                                                        setTiming(item?.Timing)
                                                        setPrice(item?.Price)
                                                        setLocation(item?.Location)
                                                        setAdultDiscount(item?.AdultDiscount)
                                                        setChildren(item?.Children)
                                                        setChildrenDiscount(item?.ChildrenDiscount)
                                                        setalg(i + 1)
                                                        setEditing(true);
                                                        setData2(item)
                                                    }} />)}
                                                <MdDelete className='text-danger' onClick={() => {
                                                    setData3(item)
                                                    handleDeleteLocation(item?._id)
                                                }} />
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose3}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default VendorAddActivity
