import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment/moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Container, Form, Modal, Table } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import ReactPaginate from "react-paginate";
import Stack from "@mui/material/Stack";
import { Pagination } from "@mui/material";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { AiFillEye } from "react-icons/ai";

function VBookedTourPack() {

    const TourVendor = JSON.parse(sessionStorage.getItem("TourId"))
    console.log("TourVendor", TourVendor);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (item) => {
        setShow(true);
        setdata(item);
    };

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => { setShow1(true); };

    const [bookedTourpackages, setbookedTourpackages] = useState([]);
    const [Traveler, setTraveler] = useState({});
    const getbookedTourPackages = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/admin/getbookedtourpackages"
            );
            if (res.status === 200) {
                setbookedTourpackages(res.data.bookedtourpackages);
                // setPagination(res.data.bookedtourpackages);
                setnochangedata(res.data.bookedtourpackages);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Delete
    const [Data, setdata] = useState("");
    const DeleteBookedTrip = async () => {
        try {
            const config = {
                url: "admin/daletebookedtrippackages/" + Data,
                baseURL: "https://hayyyak.com/api",
                method: "DELETE",
                Headers: { "Content-Type": "application-json" },
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    alert(res.data.success);
                    handleClose();
                    getbookedTourPackages();
                }
            });
        } catch (error) {
            alert(error.response.data.error);
        }
    };


    // Search
    const [SearchItem, setSearchItem] = useState("");
    // Apply search filter
    const filteredTourPackages = bookedTourpackages.filter(item =>
        SearchItem === "" ||
        (Object.values(item.userId).some(value =>
            String(value).toLowerCase().includes(SearchItem.toLowerCase())
        ) ||
            (item?.packageId?.tripname && item?.packageId?.tripname.toLowerCase().includes(SearchItem.toLowerCase())))
    );

    // Paginate the filtered dataset
    // const [pagination, setPagination] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;
    const changePage = (selected) => {
        setPageNumber(selected);
    };
    const fiteredTourpackageeforvendor = filteredTourPackages?.filter((ele) => ele?.packageId?.TourId === TourVendor?._id)
    const pageCount = Math.ceil(fiteredTourpackageeforvendor?.length / usersPerPage);
    const paginatedActivities = fiteredTourpackageeforvendor?.slice(pagesVisited, pagesVisited + usersPerPage);

    // Export function to export all filtered activities
    const exportAllData = () => {
        // Create a new array with only the data you want to export
        const dataToExport = fiteredTourpackageeforvendor.map(item => ({
            UserName: `${item.userId.Fname} ${item.userId.Lname}`,
            UserNumber: item.userId.Mobile,
            UserEmail: item.userId.Email,
            TripName: item.packageId.tripname,
            BookingDate: moment(item.createdAt).format("DD/MM/YYYY"),
            TripDuration: `${item.packageId.tripduration} Days / ${item.packageId.tripnight} Night`,
            CheckInDate: item.checkindate,
            CheckOutDate: item.CheckOutDates,
            TravellerDetails: `Adult: ${item.adultquantity}, Children: ${item.childrenquantity}, Infant: ${item.petsquantity}`,
            Location: item.packageId.city,
            ActualPrice: `Adult: ${item.packageId.tripcost}, Children: ${item.packageId.childrencost}, Infant: ${item.packageId.petscost}`,
            Discount: `Adult: ${item.packageId.tripdiscount}%, Children: ${item.packageId.childrendiscount}%, Infant: ${item.packageId.petdiscount}%`,
            TotalPrice: `${item.adultprice + item.childrenprice + item.petsprice}`,
        }));

        // Export the entire array
        exportToExcel(dataToExport);
    };

    const exportToExcel = (dataToExport) => {
        const csv = dataToExport.map(row => Object.values(row).join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "BookedTourPackage.csv";
        link.click();
    };
    // Date Filter
    const [noschangedata, setnochangedata] = useState([]);
    const [fromd, setfromd] = useState("");
    const [tod, settod] = useState("");
    const searchDate = () => {
        if (!fromd) return alert("Please select from date");
        if (!tod) return alert("Please select to date");

        let std = fromd?.split("-");
        let entd = tod?.split("-");
        let startD = parseInt(std?.join(""));
        let endD = parseInt(entd?.join(""));
        let abc = noschangedata?.filter((ele) => {
            let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
            let amd = parseInt(abd?.join(""));
            return startD <= amd && endD >= amd;
        });
        setbookedTourpackages(abc);
    };

    useEffect(() => {
        getbookedTourPackages();
    }, []);
    console.log("bookedTourpackages", bookedTourpackages);
    return (
        <>
            <div>
                <Container fluid>
                    <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
                        <p className="text-danger fs-4 fw-semibold">Booked Package List :</p>
                        {/* <button className='pink-btn' onClick={handleShow}>Add Package</button> */}
                    </div>

                    <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                        <div className="col-lg-2">
                            <label>From :</label>
                            <Form.Control type="date" aria-describedby="basic-addon1" onChange={(e) => setfromd(e.target.value)} />
                        </div>

                        <div className="col-lg-2">
                            <label>To :</label>
                            <Form.Control type="date" aria-describedby="basic-addon1" onChange={(e) => settod(e.target.value)} />
                        </div>

                        <div className="col-lg-2">
                            <button className="pink-btn" onClick={searchDate}>Submit</button>
                        </div>

                        <div
                            className="input-group col-lg-3"
                            style={{ width: "auto", height: "35px", marginTop: "20px" }}
                        >
                            <span class="input-group-text" id="basic-addon1">
                                <BsSearch />
                            </span>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Search..."
                                aria-describedby="basic-addon1"
                                onChange={(e) => setSearchItem(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-1 export-btn">
                            {/* <ReactHTMLTableToExcel
                id="export-to-excel"
                table="Export-table"
                filename="BookedTourPackage"
                sheet="BookedTourPackage"
                buttonText="Export"
                onClick={exportAllData}
              /> */}
                            <button className="button-download" onClick={exportAllData}>Export</button>
                        </div>
                    </div>
                    <div>
                        <Table responsive bordered className="admin-table-head" id="Export-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>User Name</th>
                                    <th>User Number</th>
                                    <th>User Email</th>
                                    <th>Trip Name</th>
                                    <th>Booking Date</th>
                                    <th>Trip Duration</th>
                                    <th>Check In Date</th>
                                    <th>Check Out Date</th>
                                    <th>Traveller Details</th>
                                    <th>Location</th>
                                    <th>Total Person</th>
                                    <th>Actual Price</th>
                                    <th>Discount(%)</th>
                                    <th>Discount Price</th>
                                    <th>Total Price</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedActivities?.filter((ele) => ele?.packageId?.TourId === TourVendor?._id)?.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{(pageNumber * pagesVisited) + i + 1}</td>
                                            <td>{item?.userId?.Fname} {item?.userId?.Lname}</td>
                                            <td>{item?.userId?.Mobile}</td>
                                            <td>{item?.userId?.Email}</td>
                                            <td>{item?.packageId?.tripname}</td>
                                            <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                                            <td>{item?.packageId?.tripduration} <b>Days</b> / {item?.packageId?.tripnight} <b>Night</b></td>
                                            <td>{item?.checkindate}</td>
                                            <td>{item?.CheckOutDates}</td>
                                            <td>
                                                <AiFillEye
                                                    style={{ cursor: 'pointer' }}
                                                    className="text-danger fs-5"
                                                    onClick={() => {
                                                        setTraveler(item)
                                                        handleShow1()
                                                    }} />
                                            </td>
                                            <td>{item?.packageId?.city}</td>
                                            <td>
                                                Adult:{" "}
                                                <span style={{ color: "green", fontSize: "18px" }}>
                                                    {item?.adultquantity}
                                                </span>{" "}
                                                ,<br />
                                                Children:{" "}
                                                <span style={{ color: "green" }}>
                                                    {item?.childrenquantity}
                                                </span>{" "}
                                                ,<br />
                                                Infant:{" "}
                                                <span style={{ color: "green" }}>
                                                    {item?.petsquantity}
                                                </span>
                                            </td>
                                            <td>
                                                Adult:{" "}
                                                <span style={{ color: "green", fontSize: "18px" }}>
                                                    {" "}
                                                    ₹ {item?.packageId?.tripcost}
                                                </span>{" "}
                                                ,<br />
                                                Children:{" "}
                                                <span style={{ color: "green" }}>
                                                    {" "}
                                                    ₹ {item?.packageId?.childrencost}
                                                </span>{" "}
                                                ,<br />
                                                Infant:{" "}
                                                <span style={{ color: "green" }}>
                                                    {" "}
                                                    ₹ {item?.packageId?.petscost}
                                                </span>
                                            </td>
                                            <td>
                                                Adult:{" "}
                                                <span style={{ color: "green", fontSize: "18px" }}>
                                                    {" "}
                                                    {item?.packageId?.tripdiscount} %
                                                </span>{" "}
                                                ,<br />
                                                Children:{" "}
                                                <span style={{ color: "green" }}>
                                                    {" "}
                                                    {item?.packageId?.childrendiscount} %
                                                </span>{" "}
                                                ,<br />
                                                Infant:{" "}
                                                <span style={{ color: "green" }}>
                                                    {" "}
                                                    {item?.packageId?.petdiscount} %
                                                </span>
                                            </td>
                                            <td>
                                                Adult:{" "}
                                                <span style={{ color: "green", fontSize: "18px" }}>
                                                    {" "}
                                                    ₹ {item?.adultprice}{" "}
                                                </span>{" "}
                                                ,<br />
                                                Children:{" "}
                                                <span style={{ color: "green" }}>
                                                    ₹ {item?.childrenprice}{" "}
                                                </span>{" "}
                                                ,<br />
                                                Infant:{" "}
                                                <span style={{ color: "green" }}>
                                                    {" "}
                                                    ₹ {item?.petsprice}{" "}
                                                </span>
                                            </td>
                                            <td style={{ color: "green" }}>₹{item?.adultprice + item?.childrenprice + item?.petsprice}</td>
                                            <td>
                                                <div
                                                    className="d-flex gap-2 fs-4"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <MdDelete
                                                        className="text-danger"
                                                        onClick={() => handleShow(item?._id)}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className="my-3">
                        <Stack spacing={2}>
                            <Pagination
                                count={pageCount}
                                onChange={(event, value) => {
                                    changePage(value - 1)
                                }}
                                color="primary"
                            />
                        </Stack>
                    </div>
                </Container>

                {/* Traveller Model */}
                <Modal
                    show={show1}
                    onHide={handleClose1}
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <p className="fw-bold">Total Trvellers : <span className="text-danger">{Traveler?.TravelersData?.length}</span></p>
                        <Table bordered className="sdfsd-table-head">
                            <thead>
                                <tr>
                                    <th width="20%">S.No</th>
                                    <th>Traveller Names</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Traveler?.TravelersData?.map((item, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{item?.TravellerFirstName} {item?.TravellerLastName}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className="fw-bold">Contact Details :</td>
                                    <td>
                                        Email:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {Traveler?.useremail}
                                        </span>{" "}
                                        ,<br />
                                        Number:{" "}
                                        <span style={{ color: "green" }}>
                                            {Traveler?.userphoneno}
                                        </span>{" "}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose1}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Delete Modal */}

                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <img
                                style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "5px",
                                    marginBottom: "10px",
                                }}
                                src="./img/delete-btn.png"
                                alt=""
                            />
                            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
                            <p>This data will be removed permanently</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => DeleteBookedTrip()}>
                            <FontAwesomeIcon icon={faCancel} className=" me-2" />
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default VBookedTourPack;
