import React, { useState } from "react";
import { Container, Form, Row, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { City, Country, State } from "country-state-city";
import axios from "axios";
import { useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser"

const steps = [
  "Package Details",
  "Details and Includes",
  "Gallery Image",
  "About The Tour ",
];
function VendorAddPack() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const TourVendor = JSON.parse(sessionStorage.getItem("TourId"))
  console.log("asdasd", TourVendor);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  console.log("completed", completed);
  // Tour Package Details

  const [Country1, setCountry1] = useState("");
  const [State1, setState1] = useState("");
  const [City1, setCity1] = useState("");

  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(Country1);
  const CityList = City.getCitiesOfState(Country1, State1);
  const [TripCategory, setTripCategory] = useState("");
  const [TripName, setTripName] = useState("");
  const [TripDescription, setTripDescription] = useState("");
  const [TripCost, setTripCost] = useState();
  const [TripDiscount, setTripDiscount] = useState();
  const [ChildrenCost, setChildrenCost] = useState();
  const [Childrendiscount, setChildrendiscount] = useState();
  const [Petcost, setPetcost] = useState();
  const [Petdiscount, setPetdiscount] = useState();
  const [TripDuration, setTripDuration] = useState("");
  const [TripNight, setTripNight] = useState("");
  const [location, setlocation] = useState("");
  //Details And Includes
  const [Sightseeing, setSightseeing] = useState("");
  const [Hotels, setHotels] = useState("");
  const [Transport, setTransport] = useState("");

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    if (activeStep === 0) {
      if (!TripName) {
        return (
          <>
            {alert("Enter trip name")}
            {setActiveStep(0)}
          </>
        );
      } else if (!TripDescription) {
        return (
          <>
            {alert("Enter Trip Description")}
            {setActiveStep(0)}
          </>
        );
      } else if (!TripCost) {
        return (
          <>
            {alert("Enter Adult Trip Cost..!")}
            {setActiveStep(0)}
          </>
        );
      } else if (!ChildrenCost) {
        return (
          <>
            {alert("Enter Children Cost..!")}
            {setActiveStep(0)}
          </>
        );
      } else if (!Country1) {
        return (
          <>
            {alert("Select Country")}
            {setActiveStep(0)}
          </>
        );
      } else if (!State1) {
        return (
          <>
            {alert("Select State")}
            {setActiveStep(0)}
          </>
        );
      } else if (!City1) {
        return (
          <>
            {alert("Select City")}
            {setActiveStep(0)}
          </>
        );
      } else if (!TripDuration) {
        return (
          <>
            {alert("Enter trip Deration..!")}
            {setActiveStep(0)}
          </>
        );
      }
      handleNext();
    }

    if (activeStep === 1) {
      if (!Sightseeing) {
        return <>{alert("Select Sight Seeing")}</>;
      } else if (!Hotels) {
        return <>{alert("Select Hotels")}</>;
      } else if (!Transport) {
        return <>{alert("Select Transport")}</>;
      } else {
        handleNext();
      }
    }
    if (activeStep === 2) {
      if (galleryImages.length === 0) {
        return <>{alert("Select Gallery Images")}</>;
      } else {
        handleNext();
      }
    }

    if (activeStep === 3) {
      if (tourschedule.length === 0) {
        return <>{alert("Select Placess")}</>;
      } else {
        handleNext();
      }
    }
  };


  // Add Gallery Image
  const [imageURL2, setimageURL2] = useState(null);
  const [Image, setImage] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL2(URL.createObjectURL(event.target.files[0]));
      // console.log("ghghg", URL.createObjectURL(event.target.files[0]));
    }
  };
  const AddGalleryImage = () => {
    if (!Image) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImages.find((img) => img?.imgUrl === Image);

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Image,
      imgUrl: imageURL2,
    };

    setGalleryImages([...galleryImages, obj]);
  };
  const removeItem2 = (val) => {
    const updatedGallery = galleryImages.filter((item, index) => index !== val);
    setGalleryImages(updatedGallery);
  };


  // Add Iternery
  const [tourschedule, settourschedule] = useState([]);
  const [day, setday] = useState("");
  // const [date, setdate] = useState("");
  const [todaysplan, settodaysplan] = useState("");
  const AddTourSchedule = () => {
    const scheduleExists = tourschedule?.filter(
      (ele) =>
        ele?.day === day &&
        // ele.date === date &&
        ele?.todaysplan === todaysplan
    );

    if (!scheduleExists) {
      return alert("Details is  already exists.");
    }

    const obj = {
      day: day,
      // date: date,
      todaysplan: todaysplan,
    };

    settourschedule([...tourschedule, obj]);
  };
  const removeschedule = (val) => {
    const updatedGallery = tourschedule.filter((item, index) => index !== val);
    settourschedule(updatedGallery);
  };

  // Add Faq

  const [Suggestion, setSuggestion] = useState();
  const [termsandcondition, settermsandcondition] = useState();
  const [policy, setpolicy] = useState();
  const [allFaq, setallFaq] = useState([]);
  const [question, setquestion] = useState()
  const [answer, setanswer] = useState()
  const AddFaq = () => {
    const FaqExists = allFaq?.filter(
      (ele) =>
        ele?.question === question &&
        ele?.answer === answer
    )
    if (!FaqExists) {
      return alert("FAQ details is already exists")
    }
    const obj = {
      question: question,
      answer: answer
    }
    setallFaq([...allFaq, obj])
  }
  const removeFAQ = (val) => {
    const updateFaq = allFaq.filter((item, index) => index !== val);
    setallFaq(updateFaq)
  }


  const TourpackageDetails = async () => {
    let adultofferprice = TripCost - (TripCost * TripDiscount) / 100;
    let childrenofferprice =
      ChildrenCost - (ChildrenCost * Childrendiscount) / 100;
    let petofferprice = Petcost - (Petcost * Petdiscount) / 100;
    try {
      const config = {
        url: "/admin/addtrippackages",
        method: "post",
        baseURL: "https://hayyyak.com/api",
        Headers: { "content-type": "multipart/form-data" },
        data: {
          venderaddstatus: "pending",
          TourId: TourVendor?._id,
          tripcategory: TripCategory,
          tripname: TripName,
          tripdescription: TripDescription,
          tripcost: TripCost,
          tripdiscount: TripDiscount,
          offerprice: adultofferprice,
          country: Country1,
          state: State1,
          city: City1,
          childrencost: ChildrenCost,
          childrendiscount: Childrendiscount,
          childrenoffer: childrenofferprice,
          petscost: Petcost,
          petdiscount: Petdiscount,
          petoffer: petofferprice,
          tripduration: TripDuration,
          signtseeing: Sightseeing,
          hotels: Hotels,
          transport: Transport,
          tripnight: TripNight,
          location: location,
          // GalleryImage: galleryImages,
          TourSchedule: tourschedule,
          Suggestion: Suggestion,
          termsandcondition: termsandcondition,
          policy: policy,
          itinerary: tourschedule,
          faq: allFaq
        },
      };
      await axios(config).then(async (res) => {
        if (res.status === 200) {
          for (let i = 0; i < galleryImages.length; i++) {
            const config = {
              url: "/admin/galleryimageupload",
              method: "put",
              baseURL: "https://hayyyak.com/api",
              headers: { "content-type": "multipart/form-data" },
              data: {
                productId: res.data.success?._id,
                image: galleryImages[i]?.image,
              },
            };
            await axios(config);
          }
          alert("Package Added Successfully");
          window.location.assign("/vendorpacklist");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Get Tour Category Data
  const [getTourCat, setgetTourCat] = useState([]);
  const getTourcategory = () => {
    axios
      .get("https://hayyyak.com/api/admin/tourpackage/gettourcategory")
      .then(function (response) {
        setgetTourCat(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("getTourCat", getTourCat);
  useEffect(() => {
    getTourcategory();
  }, []);

  return (
    <div>
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
          <p className="text-danger fs-4 fw-semibold">Tour Package Details:</p>

        </div>

        <div className="Stepper-info " style={{ padding: "20px" }}>
          <Box sx={{ width: "100%" }}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography
                    sx={{ mt: 2, mb: 1 }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    All steps completed successfully
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        pt: 2,
                      }}
                    >
                      <Button onClick={handleReset}>Back</Button>
                    </Box>
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {activeStep == 0 ? (
                    <>
                      <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                        <div className="container" style={{ padding: "5px" }}>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Select Category
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  className="vi_0"
                                  onChange={(e) =>
                                    setTripCategory(e.target.value)
                                  }
                                >
                                  <option>Select</option>
                                  {getTourCat?.map((item) => (
                                    <option value={item?.id}>
                                      {item?.TourCategory}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Trip Name
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Trip Name"
                                  className="vi_0"
                                  onChange={(e) => setTripName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Trip Description
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Description"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setTripDescription(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Adult Price
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="₹ Price"
                                  className="vi_0"
                                  onChange={(e) => setTripCost(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Adult Discount{" "}

                                </label>
                                <input
                                  type="text"
                                  placeholder="%Discount"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setTripDiscount(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Children Price
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="₹ For Children Price"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setChildrenCost(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Children Discount{" "}

                                </label>
                                <input
                                  type="text"
                                  placeholder="For Children % Discount"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setChildrendiscount(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Infant Price

                                </label>
                                <input
                                  type="text"
                                  placeholder=" For Infant ₹ Price"
                                  className="vi_0"
                                  onChange={(e) => setPetcost(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Infant Discount{" "}

                                </label>
                                <input
                                  type="text"
                                  placeholder="For Infant % Discount"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setPetdiscount(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Country
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  class="ebf4591c8e country-select-for-e2e-tests"
                                  name="cc1"
                                  id="cc1"
                                  className="vi_0"
                                  onChange={(e) => setCountry1(e.target.value)}
                                >
                                  <option value="">
                                    Select country/region
                                  </option>
                                  {CountryList?.map((Country) => (
                                    <option value={Country?.isoCode}>
                                      {Country?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  State
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  class="ebf4591c8e country-select-for-e2e-tests"
                                  name="cc1"
                                  id="cc1"
                                  className="vi_0"
                                  onChange={(e) => setState1(e.target.value)}
                                >
                                  <option value="">Select state</option>
                                  {StateList?.map((state1) => (
                                    <option value={state1?.isoCode}>
                                      {state1?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  City
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  className="vi_0"

                                  name="cc1"
                                  id="cc1"
                                  onChange={(e) => setCity1(e.target.value)}
                                >
                                  <option value="">
                                    Select city
                                  </option>
                                  {CityList?.map((city1) => (
                                    <option value={city1?.name}>
                                      {city1?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>


                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Days
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Days"
                                  className="vi_0"
                                  onChange={(e) =>
                                    setTripDuration(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Nights
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Nights"
                                  className="vi_0"
                                  onChange={(e) => setTripNight(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="do-sear mt-2">
                                <label>
                                  Location
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Location"
                                  className="vi_0"
                                  onChange={(e) => setlocation(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </>
                  ) : (
                    <>
                      {activeStep == 1 ? (
                        <>
                          <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                            <div
                              className="container"
                              style={{ padding: "5px" }}
                            >
                              <div className="row ">
                                <div className="col-md-6">
                                  <div className="do-sear mt-2">
                                    <label>
                                      Sight Seeing{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    {/* <input
                                      type="text"
                                      placeholder="Sight"
                                      className="vi_0"
                                    /> */}
                                    <select
                                      className="vi_0"
                                      onChange={(e) =>
                                        setSightseeing(e.target.value)
                                      }
                                    >
                                      <option>Select</option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="do-sear mt-2">
                                    <label>
                                      Hotel
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    {/* <input
                                      type="text"
                                      placeholder="Hotel"
                                      className="vi_0"
                                    /> */}
                                    <select
                                      className="vi_0"
                                      onChange={(e) =>
                                        setHotels(e.target.value)
                                      }
                                    >
                                      <option>Select</option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="do-sear mt-2">
                                    <label>
                                      Transports
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                      className="vi_0"
                                      onChange={(e) =>
                                        setTransport(e.target.value)
                                      }
                                    >
                                      <option>Select</option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </div>
                                </div>
                                {/* <div className="col-md-6">
                                  <div className="do-sear mt-2">
                                    <label>
                                      Duration
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Duration"
                                      className="vi_0"
                                    />
                                  </div>
                                </div> */}
                                {/* <div className="col-md-6">
                                  <div className="do-sear mt-2">
                                    <label>
                                      Location
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Location"
                                      className="vi_0"
                                    />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </Typography>
                        </>
                      ) : (
                        <>
                          {activeStep == 2 ? (
                            <>
                              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <div
                                  className="container"
                                  style={{ padding: "5px" }}
                                >
                                  <div className="row ">
                                    <div className="col-md-6">
                                      <div className="do-sear mt-2">
                                        <label>
                                          Gallery
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="file"
                                          className="vi_0"
                                          accept="image/*"
                                          onChange={(e) => {
                                            onImageChange2(e);
                                            setImage(e.target.files[0]);
                                          }}
                                        />
                                      </div>

                                      <button
                                        className="pink-btn mt-4 "
                                        onClick={AddGalleryImage}
                                      >
                                        Add
                                      </button>
                                    </div>

                                    <div className="col-md-6">
                                      <Table responsive bordered>
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              S.No
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Image
                                            </th>
                                            <th
                                              style={{
                                                background: "#d81d4a",
                                                color: "#fff",
                                              }}
                                            >
                                              Actions
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {galleryImages?.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  <img
                                                    src={item?.imgUrl}
                                                    alt=""
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className="d-flex gap-2 fs-4"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {/* <BiSolidEdit className="text-primary" /> */}
                                                    <MdDelete
                                                      onClick={() =>
                                                        removeItem2(i)
                                                      }
                                                      className="text-danger"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <div
                                  className="container"
                                  style={{ padding: "5px" }}
                                >

                                  <div className="row mb-3">
                                    <div className="col-lg-6 mb-2">
                                      <label className="fw-bold">Suggestion :</label>
                                      <CKEditor editor={ClassicEditor}
                                        data={Suggestion}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setSuggestion(data);
                                        }}
                                      />
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                      <label className="fw-bold">Terms and Conditions :</label>
                                      <CKEditor editor={ClassicEditor}
                                        data={termsandcondition}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          settermsandcondition(data);
                                        }}
                                      />
                                    </div>
                                    <div className="col-lg-6">
                                      <label className="fw-bold">Policy :</label>
                                      <CKEditor editor={ClassicEditor}
                                        data={policy}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setpolicy(data);
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <hr />

                                  {/* Iternery */}
                                  <div className="row mb-4">
                                    <p className="fw-bold text-danger ">ITINERARY :</p>
                                    <div className="col-md-6">
                                      <div className="row">
                                        <div className="do-sear mt-2 col-lg-12">
                                          <label>
                                            Day
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            placeholder="Enter Place Name"
                                            className="vi_0"
                                            onChange={(e) =>
                                              setday(e.target.value)
                                            }
                                          />
                                        </div>
                                        {/* <div className="do-sear mt-2 col-lg-6">
                                          <label>
                                            Date
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="date"
                                            placeholder="Enter date"
                                            className="vi_0"
                                            onChange={(e) =>
                                              setdate(e.target.value)
                                            }
                                          />
                                        </div> */}
                                      </div>
                                      <div className="do-sear mt-2">
                                        <label>
                                          Today's Plan
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <CKEditor editor={ClassicEditor}
                                          data={todaysplan}
                                          onChange={(event, editor) => {
                                            const data = editor.getData();
                                            settodaysplan(data);
                                          }}
                                        />
                                      </div>
                                      <button
                                        onClick={AddTourSchedule}
                                        className="pink-btn mt-2"
                                      >
                                        Add
                                      </button>
                                    </div>

                                    <div className="col-md-6">
                                      <Table responsive bordered className="sdfsd-table-head">
                                        <thead>
                                          <tr>
                                            <th>
                                              Days
                                            </th>
                                            {/* <th>
                                              Date
                                            </th> */}
                                            <th>
                                              Today's Plane
                                            </th>
                                            <th>
                                              Actions
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {tourschedule?.map((item, i) => {
                                            return (
                                              <tr>
                                                <td>{item?.day}</td>

                                                <td>{parse(`<div>${item?.todaysplan}</div>`)}</td>
                                                <td>
                                                  <div
                                                    className="d-flex gap-2 fs-4"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {/* <BiSolidEdit className="text-primary" /> */}
                                                    <MdDelete
                                                      onClick={() =>
                                                        removeschedule(i)
                                                      }
                                                      className="text-danger"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>

                                  <hr />

                                  {/* FAQ */}
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <p className="fw-bold text-danger ">FAQ :</p>
                                      <div className="col-lg-12 mb-2">
                                        <label className="fw-bold">
                                          Question
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Question"
                                          className="vi_0"
                                          onChange={(e) => setquestion(e.target.value)}
                                        />
                                      </div>
                                      <div className="col-lg-12">
                                        <label className="fw-bold">
                                          Answer
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Answer"
                                          className="vi_0"
                                          onChange={(e) => setanswer(e.target.value)}
                                        />
                                      </div>
                                      <button
                                        onClick={AddFaq}
                                        className="pink-btn mt-2"
                                      >
                                        Add
                                      </button>
                                    </div>
                                    <div className="col-lg-6">
                                      <Table responsive bordered className="sdfsd-table-head">
                                        <thead>
                                          <tr>
                                            <th>
                                              S.No
                                            </th>
                                            <th>
                                              Question / Answer
                                            </th>
                                            <th>
                                              Actions
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {allFaq?.map((item, i) => (
                                            <tr>
                                              <td>{i + 1}</td>
                                              <td>
                                                <div>
                                                  <p className="fw-bold">Q : {item?.question}?</p>
                                                  <p>A : {item?.answer}</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div
                                                  className="d-flex gap-2 fs-4"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <MdDelete
                                                    onClick={() =>
                                                      removeFAQ(i)
                                                    }
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          ))}

                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>

                                </div>
                              </Typography>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      style={{ background: "green", color: "#fff" }}
                      hidden={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}

                    >
                      Back
                    </Button>

                    <Box sx={{ flex: "1 1 auto" }} />
                    {activeStep !== steps.length &&
                      (completed[activeStep] ? (
                        <Typography
                          variant="caption"
                          sx={{ display: "inline-block" }}
                        >
                          Step {activeStep + 1} already completed
                        </Typography>
                      ) : (
                        <button
                          className="pink-btn"
                          onClick={() => {
                            completedSteps() === totalSteps() - 1
                              ? TourpackageDetails()
                              : handleComplete();
                          }}
                        >
                          {completedSteps() === totalSteps() - 1
                            ? "Submit"
                            : "Complete Step"}
                        </button>
                      ))}
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Box>
        </div>
      </Container>
    </div>
  );
}
export default VendorAddPack;
