import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Form, InputGroup } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
export const RestaurantsAndCafe = () => {

  const navigate = useNavigate();

  const [colorss, setcolorss] = useState(true);
  const [colorss1, setcolorss1] = useState(false);

  const [RestoCafe, setRestoCafe] = useState([]);
  const GetAdminRestaurantCafe = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/resto/Getrestoandcafes"
      );
      if (res.status === 200) {
        setRestoCafe(res.data.sucess);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetAdminRestaurantCafe();
  }, []);
  console.log("RestoCafe", RestoCafe);
  return (
    <div>
      <div className="mb-4 restaurant-div-mb">
        <div className="activity-bg">
          <div
            style={{
              backgroundImage: "url(././img/food_banner.jpg)",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "350px",
              width: "100%",
            }}
            className="restaurant-cafe-img"
          >
            <div className="activity-text">
              <div className="bread" id="restaurant-bread">
                <h2 className="text-uppercase fw-bold">Restaurants And Cafe</h2>
                <div className="d-flex align-items-center gap-2">
                  <Link to="/" className="breadcrumb-active">
                    <span>Home </span>
                  </Link>
                  <span>
                    <AiOutlineRight />
                  </span>
                  <span className="" style={{color:"#D81D4A", fontWeight:"bold"}}>Restaurants And Cafe</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container>
        <div className="row justify-content-between">
          <div className="col-lg-6 mb-2">
            <div className="d-flex gap-5">
              <button
                onClick={() => {
                  setcolorss(true);
                  setcolorss1(false);
                }}
                className={colorss ? "pink-btn1" : "pink-btn__2"}
              >
                Restaurant
              </button>
              <button
                onClick={() => {
                  setcolorss1(true);
                  setcolorss(false);
                }}
                className={colorss1 ? "pink-btn1" : "pink-btn__2"}
              >
                Cafe
              </button>
            </div>
          </div>
          <div className="col-lg-4">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <CiSearch />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search...."
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </div>
        </div>

        <p className="fs-1 mb-3 restaurant-div-mb">
          Best <span className="text-danger">Restaurant</span> and{" "}
          <span className="text-danger">Cafe</span>{" "}
        </p>
        <div>
          <div className="row mb-4 restaurant-div-mb">
            {RestoCafe?.filter((ele)=>ele.isApporved === true)?.map((item, i) => {
                return(
                    <div className="col-lg-4">
                    <div
                        onClick={()=>navigate('/restaurant_cafe_details', { state: item })}
                      className="text-decoration-none"
                    >
                      <div className="cafe_img">
                        <img                       
                        src={`https://hayyyak.com/Restoandcafes/${item?.Gallery?.[0]?.image1}`}
                         alt=""
                          />
                        <div>
                          <p className="fw-semibold fs-5 text-dark">{item?.restoname}</p>
                          <div className="flex justify-content-between">
                            <p className="text-muted fs-6">
                            {parse(`<div>${item?.kitchentype}</div>`)}
                          </p>
                          <p className="text-danger fs-5 fw-bold">₹{item?.Price}</p>
                        </div>
                        <p className="text-secondary">{item?.Address},{item?.city}</p>
                        <p className="text-danger">{parse(`<div>${item?.restoopens}</div>`)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )

            })}

            {/* <div className="col-lg-4">
                            <Link to="/restaurant_cafe_details" className="text-decoration-none">
                                <div className="cafe_img">
                                    <img src="./img/cafe-2.avif" alt="" />
                                    <div>
                                        <p className='fw-semibold fs-5 text-dark'>Taj Hotel</p>
                                        <div className="flex justify-content-between">
                                            <p className='text-muted fs-6'>Pizza, North Indian...</p>
                                            <p className='text-danger fs-5 fw-bold'>₹9220</p>
                                        </div>
                                        <p className='text-secondary'>Domlur Bangalore</p>
                                        <p className='text-danger'>Opens at 11am</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-4">
                            <Link to="/restaurant_cafe_details" className="text-decoration-none">
                                <div className="cafe_img">
                                    <img src="./img/cafe-3.avif" alt="" />
                                    <div>
                                        <p className='fw-semibold fs-5 text-dark'>Taj Hotel</p>
                                        <div className="flex justify-content-between">
                                            <p className='text-muted fs-6'>Pizza, North Indian...</p>
                                            <p className='text-danger fs-5 fw-bold'>₹9220</p>
                                        </div>
                                        <p className='text-secondary'>Domlur Bangalore</p>
                                        <p className='text-danger'>Opens at 11am</p>
                                    </div>
                                </div>
                            </Link>
                        </div> */}
          </div>
        </div>
      </Container>
    </div>
  );
};
