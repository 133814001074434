import React, { useState } from "react";
import { Container, Form, Table } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useRef } from "react";
import axios from "axios";
import { useEffect } from "react";
import parse from "html-react-parser"
import moment from "moment/moment";
import { Rating } from "react-simple-star-rating";

function VendorAddHotel() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show9, setShow9] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const handleShow9 = () => setShow9(true)
  const handleClose9 = () => setShow9(false)


  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = (item) => {
    setShow5(true);
    setdata(item);
  };

  // input file access
  const fileInputRef = useRef(null);
  const [amid, setamid] = useState("");

  const handleIconClick = (id) => {
    setamid(id)
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const StayVendor = JSON.parse(sessionStorage.getItem("Stays"));

  const [ModelAminity, setModelAminity] = useState({});
  const [Category, setCategory] = useState({});
  // get Stays Proparty
  const [Modelview, setModelview] = useState({});
  const [BigImage, setBigImage] = useState({});
  const [StaysDetails, setStaysDetails] = useState([]);
  const getStays = async () => {
    try {
      const res = await axios.get(
        `https://hayyyak.com/api/vendor/stays/staysdetailbyid/${StayVendor?._id}`
      );
      if (res.data && res.status === 200) {
        setStaysDetails(res.data.stays);
        setnochangedata(res.data.stays);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("Modelview", Modelview);


  // Delete
  const [Data, setdata] = useState("");
  const DeleteTrip = async () => {
    try {
      const config = {
        url: "/vendor/stays/detelestays/" + Data,
        baseURL: "https://hayyyak.com/api",
        method: "DELETE",
        Headers: { "Content-Type": "application-json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose5();
          getStays();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  // Delete Review
  const DeleteReview = async (id) => {
    try {
      const config = {
        url: "/vendor/stays/detelereview/" + id,
        baseURL: "https://hayyyak.com/api",
        method: "delete",
        headers: { "Content-Type": "application/json" },
        data: {
          StayID: Modelview?._id
        }
      }
      const res = await axios(config)
      if (res.status === 200) {
        alert(res.data.success)
        getStays();
        handleClose9()
      }
    } catch (error) {
      alert(error.response.data.error)
    }
  }

  const handledeleteAmenity = (id) => {
    const confirmDlete = window.confirm("Are your sure you want to delete this item")
    if (confirmDlete) {
      deleteAmenity(id)
    }
  }
  // Delete Stay Amenities
  const deleteAmenity = async (id) => {
    try {
      const config = {
        url: "admin/stays/aminitiesdelete/" + id,
        baseURL: "https://hayyyak.com/api/",
        method: "delete",
        headers: { 'content-type': 'application/json' },
        data: {
          stayid: Modelview?._id,
        },
      }
      const res = await axios(config);
      if (res.status === 200) {
        alert("Deleted Successfully");
        setModelview(res.data.success);
        getStays()
      }
    } catch (error) {
      alert(error.res.data.error)
    }
  }

  const handleDeleteStayCategory = (id) => {
    const confirmDlete = window.confirm("Are your sure you want to delete this item")
    if (confirmDlete) {
      DeleteStayCategory(id)
    }
  }
  // Delete StayCategory
  const DeleteStayCategory = async (id) => {
    try {
      const config = {
        url: "admin/stays/categoriesdelete/" + id,
        baseURL: "https://hayyyak.com/api/",
        method: "delete",
        headers: { "Content-Type": "application/json" },
        data: {
          stayid: Modelview?._id,
        }
      }
      const res = await axios(config)
      if (res.status === 200) {
        alert("Deleted Successfully");
        setModelview(res.data.success)
        getStays()
      }
    } catch (error) {
      alert(error.res.data.error)
    }
  }

   // Update Galley
   const formData = new FormData();
   const UpdateGalleryImages = async (img) => {
     formData.append("Image", img)
     formData.append("packageid", Modelview?._id)
     formData.append("galleryid", amid);
     try {
       const config = {
         url: "/admin/stays/staysgallerydetails",
         method: "put",
         baseURL: "https://hayyyak.com/api",
         headers: { "Content-Type": "multipart/form-data" }, // Corrected the typo in headers
         data: formData,
       };
       const response = await axios(config);
       if (response.status === 200) {
         setModelview(response.data.success)
         alert("Updated Successfully");
         getStays()
       }
     } catch (error) {
       console.log(error);
     }
   };
 
   const handleDeleteGallery = (id) => {
     const confirmDlete = window.confirm("Are your sure you want to delete this item")
     if (confirmDlete) {
       DeleteGalleryimage(id)
     }
   }
 
   //Delete gallery Image
   const DeleteGalleryimage = async (id) => {
     try {
       const config = {
         url: "/admin/stays/staysgallerydelete/" + id,
         method: "delete",
         baseURL: "https://hayyyak.com/api",
         header: { "content-type": "application/json" },
         data: {
           stayid: Modelview?._id,
         },
       };
       await axios(config).then((res) => {
         if (res.status === 200) {
           alert("Successfully Delete");
           setModelview(res.data.success);
           getStays()
         }
       });
     } catch (error) {
       console.log(error);
       alert(error.response.data.error);
     }
   };

  useEffect(() => {
    getStays();
  }, []);

  // Search
  const [SearchItem, setSearchItem] = useState("");

  // Date Filter
  const [noschangedata, setnochangedata] = useState([]);
  const [fromd, setfromd] = useState("");
  const [tod, settod] = useState("");
  const searchDate = () => {
    if (!fromd) return alert("Please select from date");
    if (!tod) return alert("Please select to date");

    let std = fromd?.split("-");
    let entd = tod?.split("-");
    let startD = parseInt(std?.join(""));
    let endD = parseInt(entd?.join(""));
    let abc = noschangedata?.filter((ele) => {
      let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
      let amd = parseInt(abd?.join(""));
      return startD <= amd && endD >= amd;
    });
    setStaysDetails(abc);
  };

  return (
    <div>
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
          <p className="text-danger fs-4 fw-semibold">Stays List :</p>
          <Link to="/addnewstay">
            <button className="pink-btn">Add New Property</button>
          </Link>
        </div>

        <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
          <div className="col-lg-2">
            <label>From :</label>
            <Form.Control type="date" aria-describedby="basic-addon1" onChange={(e) => setfromd(e.target.value)} />
          </div>

          <div className="col-lg-2">
            <label>To :</label>
            <Form.Control type="date" aria-describedby="basic-addon1" onChange={(e) => settod(e.target.value)} />
          </div>

          <div className="col-lg-2">
            <button className="pink-btn" onClick={searchDate}>Submit</button>
          </div>

          <div
            className="input-group col-lg-4"
            style={{ width: "auto", height: "35px", marginTop: "20px" }}
          >
            <span class="input-group-text" id="basic-addon1">
              <BsSearch />
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              aria-describedby="basic-addon1"
              onChange={(e) => setSearchItem(e.target.value)}
            />
          </div>
        </div>

        <div>
          <Table responsive bordered className="admin-table-head">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Vendor Name</th>
                <th>Vendor Number</th>
                <th>Vendor Email</th>
                <th>Property Images</th>
                <th>Amenities</th>
                <th>Category</th>
                <th>Register date</th>
                <th>Last Update date</th>
                <th>Status</th>
                <th>Reviews</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {StaysDetails?.map((item, i) => {
                if (
                  SearchItem === "" ||
                  item?.vendorId?.Fname?.toLowerCase()?.includes(
                    SearchItem?.toLowerCase()
                  ) ||
                  item?.propartyname?.toLowerCase()?.includes(
                    SearchItem?.toLowerCase()
                  )
                )
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        {item?.vendorId?.Fname} {item?.vendorId?.Lname}
                      </td>
                      <td>{item?.vendorId?.Mobile}</td>
                      <td>{item?.vendorId?.Email}</td>
                      <td>
                        <img
                          src={`https://hayyyak.com/VendorStay/${item?.propartfullimage}`}
                          alt=""
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                        <p
                          className="Ldhfh_0"
                          onClick={() => {
                            handleShow3();
                            setModelview(item);
                          }}
                        >
                          View More
                        </p>
                      </td>
                      <td>
                        <button className="pink-btn"
                          onClick={() => { handleShow4(); setModelview(item); }}>
                          View
                        </button>
                      </td>
                      <td>
                        <button className="pink-btn"
                          onClick={() => { handleShow6(); setModelview(item); }}>
                          View
                        </button>
                      </td>
                      <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                      <td>{moment(item?.updatedAt).format("DD/MM/YYYY")}</td>
                      <td>
                        <div className="d-flex gap-2 align-items-center">
                          <span
                            className="fs-3 text-danger"
                            style={{ cursor: "pointer" }}
                          >
                            <AiFillEye
                              onClick={() => {
                                handleShow();
                                setModelview(item);
                              }}
                            />
                          </span>

                          <div>
                            {item?.Status === "Pending" ? (
                              <span
                                className="unblock-user"
                                style={{ backgroundColor: "#dba709" }}
                              >
                                Pending
                              </span>
                            ) : (
                              <span className="unblock-user">Approved</span>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="fs-4 text-danger" >
                          <AiFillEye
                            onClick={() => {
                              handleShow9()
                              setModelview(item)
                            }}
                            style={{ cursor: 'pointer' }} />
                        </div>
                      </td>
                      <td>
                        <div
                          className="d-flex gap-2 align-items-center"
                          style={{ cursor: "pointer" }}
                        >
                          {item?.Status === "Pending" ? (<>
                            <Link
                              state={{ data: item }}
                              to="/editnewstay">
                              <BiSolidEdit className="text-primary fs-4" />
                            </Link>
                          </>
                          ) : (
                            ""
                          )}

                          <MdDelete
                            className="text-danger fs-4"
                            onClick={() => {
                              handleShow5();
                              setdata(item?._id);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      {/* Details Modal */}
      <Modal show={show} onHide={handleClose} size="xl" className="vendorli">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="fs-3 mb-2">
              Property <span className="text-danger">Details </span>
            </p>
            <Table responsive bordered className="mb-2">
              <tbody>
                <tr>
                  <td className="fw-bold">Property Name :</td>
                  <td>{Modelview?.propartyname}</td>
                  <td className="fw-bold">Property Location :</td>
                  <td>{Modelview?.location}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Starting Price :</td>
                  <td>{Modelview?.startingprice}</td>
                  <td className="fw-bold">Single / Multiple Property :</td>
                  <td>{Modelview?.porparty}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Rooms :</td>
                  <td>{Modelview?.totalroom} Rooms</td>
                  <td className="fw-bold">Property Address:</td>
                  <td>{Modelview?.propartyaddress}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Check In Time :</td>
                  <td>{Modelview?.checkin}</td>
                  <td className="fw-bold">Check Out Time :</td>
                  <td>{Modelview?.checkout}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Category :</td>
                  <td>
                    <ul style={{ paddingLeft: "15px" }}>
                      {Modelview?.StaysCategory?.map((item) => (<>
                        <li>{item?.Category}</li>
                      </>))}
                    </ul>
                  </td>
                  <td className="fw-bold">Property Description :</td>
                  <td style={{ width: "400px" }}>{parse(`<div>${Modelview?.description}</div>`)}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Terms & Conditions :</td>
                  <td>
                    {parse(`<div>${Modelview?.termsandcondition}</div>`)}
                  </td>
                  <td className="fw-bold">House Rules :</td>
                  <td>{parse(`<div>${Modelview?.Houserules}</div>`)}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Near By Location :</td>
                  <td>
                    {parse(`<div>${Modelview?.Nearbyplaces}</div>`)}
                  </td>
                  <td className="fw-bold">Things To Do :</td>
                  <td>{parse(`<div>${Modelview?.ThingToDo}</div>`)}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Suggestions :</td>
                  <td>{parse(`<div>${Modelview?.suggesition}</div>`)}</td>
                </tr>
              </tbody>
            </Table>
            <p className="text-danger fw-bold">FAQ :</p>
            <Table bordered className="admin-table-head" style={{ width: "500px" }}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Question / Answer</th>
                </tr>
              </thead>
              <tbody>
                {Modelview?.faq?.map((item, i) => (
                  <tr>
                    <td>{i + 1}.</td>
                    <td>
                      <div>
                        <p className="fw-bold text-start">{parse(`<div>Q : ${item?.question}?</div>`)}</p>
                        <p>{parse(`<div className="d-flex gap-1"> A : ${item?.answer}</div>`)}</p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Documents big image Modal*/}
      <Modal show={show1} onHide={handleClose1} className="mb-0" size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0">
          <img
            src="./img/aadhaar-card.webp"
            alt=""
            style={{
              width: "-webkit-fill-available",
              imageRendering: "pixelated",
            }}
          />
        </Modal.Body>
      </Modal>

      {/* Property Big Image Modal */}
      <Modal show={show2} onHide={handleClose2} className="mb-0" size="lg">
        <Modal.Body className="p-0">
          <img
            src={`https://hayyyak.com/VendorStay/${BigImage?.image}`}
            alt=""
            style={{
              width: "-webkit-fill-available",
              imageRendering: "pixelated",
              height: '500px'
            }}
            onClick={handleClose2}
          />
        </Modal.Body>
      </Modal>

      {/* Gallery Image Model */}
      <Modal show={show3} onHide={handleClose3} className="gallery_mdl">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Gallery Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered className="sdfsd-table-head">
            <thead>
              <th>Sl.No</th>
              <th>Image</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {Modelview?.gallery?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <img
                        style={{
                          width: "100px",
                          height: '100px',
                          cursor: "pointer",
                          imageRendering: "pixelated",
                        }}
                        src={`https://hayyyak.com/VendorStay/${item?.image}`}
                        alt=""
                        onClick={() => {
                          handleShow2()
                          setBigImage(item)
                        }}
                      />
                    </td>
                    <td>
                      <div
                        className="d-flex gap-2 align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={(e) => {
                            UpdateGalleryImages(e.target.files[0], item._id);
                          }}
                        />
                        <BiSolidEdit
                          className="text-primary fs-4"
                          type="file"
                          onClick={() => handleIconClick(item?._id)}
                        />
                        <MdDelete className="text-danger fs-4"
                          onClick={() => {
                            handleDeleteGallery(item?._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose3}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Amenities Model */}
      <Modal show={show4} onHide={handleClose4} className="anties_mdl">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Amenities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered className="sdfsd-table-head">
            <thead>
              <th>Sl.No</th>
              <th>Image</th>
              <th>Name</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {Modelview?.Amenities?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <img
                        style={{ width: "80px" }}
                        src={`https://hayyyak.com/StayAmenities/${item?.Icon}`}
                        alt=""
                      />
                    </td>
                    <td>{item?.AmenitiName}</td>
                    <td>
                      <MdDelete className="text-danger fs-4" onClick={() => { handledeleteAmenity(item?._id) }} />
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose4}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Category Model */}
      <Modal show={show6} onHide={handleClose6} className="anties_mdl">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Categories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered className="sdfsd-table-head">
            <thead>
              <th>Sl.No</th>
              <th>Image</th>
              <th>Name</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {Modelview?.StaysCategory?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <img
                        style={{ width: "80px" }}
                        src={`https://hayyyak.com/StayCategory/${item?.Icon}`}
                        alt=""
                      />
                    </td>
                    <td>{item?.Category}</td>
                    <td>
                      <MdDelete className="text-danger fs-4" onClick={() => handleDeleteStayCategory(item?._id)} />
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose6}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Model */}
      <Modal show={show5} onHide={handleClose5} className="anties_mdl">
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Amenities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
              src="./img/delete-btn.png"
              alt=""
            />
            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
            <p>This data will be removed permanently</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose5}>
            Cancel
          </Button>
          <Button variant="danger" onClick={DeleteTrip}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Review Model */}
      <Modal show={show9} onHide={handleClose9} className="amenties-modal">
        <Modal.Header closeButton>
          <Modal.Title>Customer <span className="text-danger">Reviews</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Modelview?.StarRatings?.map((item) => (
            <>
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <div className="d-flex gap-2 align-items-center mb-2">
                    {item?.UserImage ? (<>
                      <img src={`https://hayyyak.com/Customer/${item?.UserImage}`} alt="" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                    </>) : (<>
                      <img src="./img/profile.jpg" alt="" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                    </>)}
                    <div style={{ fontSize: '14px' }}>
                      <p className="fw-bold ">{item?.Fname} <span className="text-danger">{item?.Lname}</span></p>
                      <Rating
                        className="StarSize"
                        readonly={true}
                        initialValue={item?.Rating} />
                    </div>
                  </div>
                  <p style={{ fontSize: '14x' }}>{item?.Review}</p>
                </div>
                <span className="text-danger fs-5" style={{ cursor: 'pointer' }}><MdDelete onClick={() => { DeleteReview(item?._id) }} /></span>
              </div>
              <hr />
            </>
          ))}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose9} >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}
export default VendorAddHotel;
