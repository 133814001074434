import React, { useEffect, useState } from 'react'
import { Container, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';


function AllTourVendorList() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show2, setShow2] = useState(false);

    // // get vehicle model
    const [getsubscriptions, setgetsubscriptions] = useState([]);
    const GetVendorSubscriptions = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/vendor/VendorGetSubscription"
            )
            if (res.status === 200) {
                setgetsubscriptions(res.data.success)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const VendorSubscriptionss = getsubscriptions.filter((ele) => ele.VendorType === "Tour Package Vendor")

    // // get vehicle model
    const [gatVendorData, setgatVendorData] = useState([]);
    const GetVendorData = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/vendor/getallTourVendor"
            )
            if (res.status === 200) {
                setgatVendorData(res.data.Vendor)
            }
        } catch (error) {
            console.log(error);
        }
    }


    // Block and Unblock
    const BlockVendor = async (blockId) => {
        try {
            const res = await axios.put(`https://hayyyak.com/api/vendor/BlockTourVendor/${blockId}`)
            if (res.status === 200) {
                notify(res.data.success)
                notify1(res.data.msg)
                GetVendorData()
            }
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    useEffect(() => {
        GetVendorData()
        GetVendorSubscriptions()
    }, [])
    const today = (moment().format("DD/MM/YYYY")?.split("/")?.reverse()?.join(""))


    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2 mb-3'>
                    <p className="text-danger fs-4 fw-semibold">Tour Package Vendor List :</p>
                </div>


                <div >
                    <Table responsive bordered className='sdfsd-table-head'>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Vendor Name</th>
                                <th>Vendor Number</th>
                                <th>Vendor Email</th>
                                <th>Subscription</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gatVendorData?.map((item, i) => {
                                return (<>
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{item?.Fname}{" "}{item?.Lname}</td>
                                        <td>{item?.Mobile}</td>
                                        <td>{item?.Email}</td>
                                        <td>
                                            <div>
                                                {VendorSubscriptionss?.some(ele => {
                                                    const enddate = ele?.EndDate?.split("/")?.reverse()?.join("")

                                                    return ele.VendorID === item?._id && parseInt(enddate) >= parseInt(today)

                                                }) ? (
                                                    <span className='unblock-user'>Subscribed</span>
                                                ) : (
                                                    <span className='block-user'>Not Subscribed</span>
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2 fs-4" style={{ cursor: 'pointer' }}>
                                                <span
                                                    onClick={() => BlockVendor(item?._id)}
                                                    className={item?.BlockVendor ? 'block-user' : 'unblock-user'}>
                                                    {item?.BlockVendor ? 'Block' : 'Unblock'}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            })}
                        </tbody>
                    </Table>
                </div>
            </Container>
        </div >
    )
}
export default AllTourVendorList
