import React, { useEffect, useState } from 'react'
import { Button, Container, Form, Modal, Table } from 'react-bootstrap'
import { BsFillBusFrontFill } from 'react-icons/bs'
import { FaHotel } from 'react-icons/fa'
import HikingIcon from '@mui/icons-material/Hiking';
import axios from 'axios';
import { AiFillEye } from 'react-icons/ai';
import parse from 'html-react-parser'

function V_Things_Dashboard() {
    const RestaurantVendor = JSON.parse(sessionStorage.getItem("RestaurantVendor"))

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => { setShow1(true); };


    const [bookedActivity, setbookedactivity] = useState([]);
    const [Traveler, setTraveler] = useState({});
    const GetBookedActivity = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/customer/activity/getbookedactivity"
            );
            if (res.status === 200) {
                setbookedactivity(res.data.bookedActivity);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        GetBookedActivity()
    }, [])

    return (
        <Container >
            <div className="row gap-2 align-items-center justify-content-center mt-3">
                <div
                    className="col-lg-5 text-light p-3 mb-3"
                    style={{
                        backgroundColor: "rgb(71 171 21)",
                        borderRadius: "10px",
                    }}
                >
                    <h6 className="fs-4 fw-bold"> Things To Do</h6>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <HikingIcon className="fs-2" />
                        <span className="fs-2 fw-bold">{bookedActivity?.filter((ele) => ele?.ActivityId?.actiitiyid === RestaurantVendor?._id)?.length}</span>
                    </div>
                    <p>{bookedActivity?.filter((ele) => ele?.ActivityId?.actiitiyid === RestaurantVendor?._id)?.length} Booking of Activity</p>
                </div>
            </div>
        </Container>
    )
}

export default V_Things_Dashboard