import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { BiLogOutCircle, BiUserCircle } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { Link } from 'react-router-dom'

function V_Things_Header() {

    const ThingsVendor = JSON.parse(sessionStorage.getItem("ThingsVendor"));

    const ActivityVendorLogout = async () => {
        try {
            sessionStorage.removeItem("ThingsVendor");
            window.location.assign("/Vendor_Things_Login")
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div
                className="header"
                style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
            >
                <Navbar expand="lg" style={{ marginLeft: "4rem" }}>
                    <Container fluid>
                        <Navbar.Brand href="">

                            {/* <img
                                    src={`https://hayyyak.com/admin/${admin?.profile}`}
                                    alt="logo"
                                    style={{
                                        width: "75px",
                                        height: "75px",
                                        borderRadius: "50%",
                                        border: "2px solid black",
                                    }}
                                /> */}


                            <Link to="/Vendor_Things_Profile" className='text-decoration-none'>
                                <div className='d-flex gap-1 align-items-center'
                                    style={{
                                        fontSize: "17px",
                                        fontWeight: "600",
                                        color: "white",
                                    }}
                                >
                                    <BiUserCircle style={{ fontSize: "35px", color: '#ebc44c' }} />
                                    {ThingsVendor?.Fname} {ThingsVendor?.Lname}
                                </div>
                            </Link>

                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav
                                className="ms-auto my-2 my-lg-0 "
                                // style={{ maxHeight: "100px" }}
                                navbarScroll
                            >

                                {/* <div className='d-flex justify-content-end align-items-center me-2 '>
                                    <div className="input-group search-width" style={{ width: '250px' }}>
                                        <span className="input-group-text" id="basic-addon1"><BsSearch /></span>
                                        <input type="text" className="form-control " placeholder="Search" aria-describedby="basic-addon1" />
                                    </div>
                                </div> */}

                                <Nav.Link
                                    onClick={ActivityVendorLogout}
                                    style={{ color: '#fff', border: '2px solid #fff', borderRadius: '10px' }}>
                                    <span>LOGOUT</span>
                                    <BiLogOutCircle
                                        style={{ fontSize: "25px", color: '#ebc44c' }}
                                    />
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    )
}

export default V_Things_Header