import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import { AiOutlineFieldTime, AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import parse from "html-react-parser";
import { Country, State, City } from "country-state-city";
import Carousel from "react-grid-carousel";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { faPersonWalkingDashedLineArrowRight } from "@fortawesome/free-solid-svg-icons";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export const Activity = () => {
  const [Country1, setCountry1] = useState("");
  const [State1, setState1] = useState("");
  const [City1, setCity1] = useState("");
  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityList, setCityList] = useState([]);

  const [CategoriesFilter, setCategoriesFilter] = useState("");
  console.log("CategoriesFilter", CategoriesFilter);
  useEffect(() => {
    const countries = Country.getAllCountries();
    setCountryList(countries);
  }, []);

  useEffect(() => {
    if (Country1) {
      const states = State.getStatesOfCountry(Country1);
      setStateList(states);
    }
  }, [Country1]);

  useEffect(() => {
    if (Country1 && State1) {
      const cities = City.getCitiesOfState(Country1, State1);
      setCityList(cities);
    }
  }, [Country1, State1]);

  const [GetActivitycat, setGetActivitycat] = useState([]);
  const GetActivityCategory = () => {
    axios
      .get("https://hayyyak.com/api/admin/activity/getactivitycategory")
      .then(function (response) {
        console.log(response.data);
        setGetActivitycat(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("GetActivitycat", GetActivitycat);

  // Get activity
  const [GetActivity, setGetActivity] = useState([]);
  const [filteredarray, setfilteredarray] = useState([]);
  const GetActivityDetail = async () => {
    try {
      const res = await axios.get(
        "https://hayyyak.com/api/admin/activity/getactivitydetail"
      );
      if (res.status === 200) {
        setGetActivity(res.data.getActivity);
        setfilteredarray(res.data.getActivity);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("filteredarray", filteredarray);

  // Filter Category
  const [filtercat, setfiltercat] = useState("");
  useEffect(() => {
    const activitycatfilter = GetActivity?.filter(
      (title) => title?.activitycategories?.Title === filtercat
    );
    setfilteredarray(activitycatfilter);
  }, [filtercat]);

  useEffect(() => {
    GetActivityDetail();
    GetActivityCategory();
    window.scrollTo(0, 0);
  }, []);

  // onCLick Scroll
  function scrollToItem(catid) {
    const element = document.getElementById(catid);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  //Pagination
  //  const [pagination, setPagination] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const changePage = (selected) => {
    setPageNumber(selected);
  };
  const AproovedActivities = filteredarray?.filter(
    (ele) =>
      ele?.vendorstatus === "Approved" &&
      (!CategoriesFilter || ele.activitycategories?.Title === CategoriesFilter)
  );
  const pageCount = Math.ceil(AproovedActivities.length / usersPerPage);
  const paginationActivities = AproovedActivities.slice(
    pagesVisited,
    pagesVisited + usersPerPage
  );

  console.log("data hai bhai ye", [
    ...new Set(
      filteredarray
        ?.filter((ele) => ele?.vendorstatus === "Approved")
        .map((item) => item?.activitycategories?.Title)
    ),
  ]);

  return (
    <div>
      <div className="mb-4 activities-div-mb">
        <div className="activity-bg">
          <div
            style={{
              backgroundImage: "url(././img/activity-bg.jpg)",
              backgroundPosition: "initial",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "350px",
              width: "100%",
            }}

            className="activity-top-img"
          >
            <div className="activity-text">
              <div className="bread">
                <h2 className="text-uppercase fw-bold">Activities</h2>
                <div className="d-flex align-items-center gap-2">
                  <Link to="/" className="breadcrumb-active">
                    <span>Home </span>
                  </Link>
                  <span>
                    <AiOutlineRight />
                  </span>
                  <span className="" style={{color:"#D81D4A", fontWeight:"bold"}}>Activities</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container>
        {/* <div>
          <div className="col-lg-3">
            <label className="fw-bold"> Popular Categories :</label>
            <div class="input-group mb-3">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setfiltercat(e.target.value)}
              >
                <option>Select Categories</option>
                {GetActivitycat?.map((title) => (
                  <option value={title.Title}>
                    {parse(`<div>${title.Title}</div>`)}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
        </div> */}

        <div className="mb-3 agsjhj_01 activities-div-mb">
          <Carousel cols={4} rows={1} gap={0} loop  >
            {[
              ...new Set(
                filteredarray
                  ?.filter((ele) => ele?.vendorstatus === "Approved")
                  .map((item) => item?.activitycategories?.Title)
              ),
            ].map((Titles) => (
              <Carousel.Item key={Titles} >
                <div
                  className="bn632-hover bn28"
                  onClick={() => {
                    setCategoriesFilter(Titles);
                  }}
                >
                  <p className="">{Titles}</p>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>

        <p className="fs-3 activity-h2 fw-bold mb-3 activities-p-mb">
          <div
            style={{
              borderBottom: "2px solid rgb(245 74 138)",
              width: "fit-content",
              margin: "auto",
            }}
          >
            {CategoriesFilter}
          </div>
        </p>

        {paginationActivities?.map((item, i) => {
          if (i % 2 === 0) {
            return (
              <div
                className="row align-items-center  mb-5 trekking-div-mb"
                // key={item?.activitycategories?.Title}
                // id={item?.activitycategories?.Title}
              >
                <div className="col-lg-6 mb-2">
                  <img
                    src={`https://hayyyak.com/ActivityCategory/${item?.activitycategories?.Image}`}
                    alt=""
                    className="activity-img"
                  />
                </div>

                <div className="col-lg-6">
                  <div className=" mb-4 ">
                    {/* <h2>Grand  <span style={{ color: 'rgb(244, 54, 79)' }}>Hot Air Baloon </span>Trips</h2> */}
                    <h2 className="activity-h2">
                      {parse(`<div>${item?.activitycategories?.Title}</div>`)}
                    </h2>
                    <p className="text-secondary">
                      {parse(
                        `<div>${item?.activitycategories?.Description}</div>`
                      )}
                    </p>
                  </div>

                  <div className="d-flex gap-4 align-items-center activity-cart" >
                    <div
                      style={{
                        borderRight: "1px solid #00000047",
                        padding: "8px",
                      }}
                    >
                      <span className="text-light" style={{ fontSize: "12px" }}>
                        <CiLocationOn /> {item?.addlocation[0]?.City}{" "}
                        {item?.addlocation[0]?.State}{" "}
                        {item?.addlocation[0]?.Country}
                      </span>
                      <p className="mb-1 fw-semibold">
                        {item?.addlocation[0]?.Location}
                      </p>
                      <span className="text-light" style={{ fontSize: "15px" }}>
                        {/* <BsFillCalendarCheckFill /> 
                        Trip Days:{" "}
                        {item?.addlocation[0]?.Day}  */}
                        <AiOutlineFieldTime /> Duration:{" "}
                        {item?.addlocation[0]?.Timing}
                      </span>
                    </div>
                    <div>
                      <span className="text-light" style={{ fontSize: "12px" }}>
                        {" "}
                        Starts From{" "}
                        <s className="fw-bold">
                          ₹{item?.addlocation[0]?.Price}
                        </s>
                      </span>
                      <p>
                        {" "}
                        <span style={{ color: "#e92c44", fontWeight: "bold" }}>
                          ₹{item?.addlocation[0]?.Adulttotal}
                        </span>{" "}
                        / person
                      </p>
                      <Link to="/activitydetails" state={{ item: item }}></Link>{" "}
                      <br />
                      <Link
                        className="text-decoration-none text-danger"
                        to="/multipleactivity"
                        state={{ item: item }}
                      >
                        <button className="pink-btn ">View All</button>
                      </Link>
                    </div>
                    <div className="activity-offer">
                      {item?.addlocation[0]?.AdultDiscount}%Off
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div
                // key={item?.activitycategories?.Title}
                // id={item?.activitycategories?.Title}
                className="row  align-items-center mb-5 activities-div-mb"
              >
                <div className="col-lg-6">
                  <div className=" mb-4 activities-div-mb">
                    {/* <h2>Jungle  <span style={{ color: 'rgb(244, 54, 79)' }}>Safari </span></h2> */}
                    <h2 className="activity-h2">
                      {parse(`<div>${item?.activitycategories?.Title}</div>`)}
                    </h2>
                    <p className="text-dark">
                      {parse(
                        `<div>${item?.activitycategories?.Description}</div>`
                      )}
                    </p>
                  </div>

                  <div className="d-flex gap-4 align-items-center activity-cart">
                    <div
                      style={{
                        borderRight: "1px solid #00000047",
                        padding: "8px",
                      }}
                    >
                      <span className="text-light" style={{ fontSize: "12px" }}>
                        <CiLocationOn /> {item?.addlocation[0]?.City}{" "}
                        {item?.addlocation[0]?.State}{" "}
                        {item?.addlocation[0]?.Country}
                      </span>
                      <p className="mb-1 fw-semibold">
                        {item?.addlocation[0]?.Location}
                      </p>
                      <span className="text-light" style={{ fontSize: "15px" }}>
                        {/* <BsFillCalendarCheckFill /> 
                        Trip Days:{" "}
                        {item?.addlocation[0]?.Day}  */}
                        <AiOutlineFieldTime /> Duration:{" "}
                        {item?.addlocation[0]?.Timing}
                      </span>
                    </div>
                    <div className="buggee-jump0ffer">
                      <span className="text-light" style={{ fontSize: "12px" }}>
                        {" "}
                        Starts From{" "}
                        <s className="fw-bold">
                          ₹{item?.addlocation[0]?.Price}
                        </s>
                      </span>
                      <p>
                        {" "}
                        <span style={{ color: "#e92c44", fontWeight: "bold" }}>
                          ₹{item?.addlocation[0]?.Adulttotal}
                        </span>{" "}
                        / person
                      </p>
                      <Link
                        className="text-decoration-none text-danger"
                        to="/multipleactivity"
                        state={{ item: item }}
                      >
                        <button className="pink-btn ">View All</button>
                      </Link>
                    </div>
                    <div className="activity-offer">
                      {item?.addlocation[0]?.AdultDiscount}%Off
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 ">
                  <img
                    src={`https://hayyyak.com/ActivityCategory/${item?.activitycategories?.Image}`}
                    alt=""
                    className="activity-img float-end"
                  />
                </div>
              </div>
            );
          }
        })}
        <div className="my-3">
          <Stack spacing={2}>
            <Pagination
              count={pageCount}
              onChange={(event, value) => {
                changePage(value - 1);
              }}
              color="primary"
            />
          </Stack>
        </div>
      </Container>
    </div>
  );
};
