import React, { useEffect, useState } from 'react'
import { Container, Form, Table } from 'react-bootstrap'
import { BiSolidEdit } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import moment from 'moment'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


function AdminVehicle() {
    const notify = (data) => toast.success(data);
    const notify1 = (data) => toast.warn(data);
    const notify2 = (data) => toast.error(data);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [VehicleImg, setVehicleImg] = useState("")
    const [VehicleCategory, setVehicleCategory] = useState("")
    const [VehicleModel, setVehicleModel] = useState("")
    const [VehicleNumber, setVehicleNumber] = useState("")
    const [VehicleCapacity, setVehicleCapacity] = useState("")
    const [VehicleType, setVehicleType] = useState("")
    const [VehicleFuelType, setVehicleFuelType] = useState("")
    const [VehicleRCImg, setVehicleRCImg] = useState("")
    const [VehicleInuranceImg, setVehicleInuranceImg] = useState("")
    const [VehicleEmmision, setVehicleEmmision] = useState("")
    const [VehicleOthDoc, setVehicleOthDoc] = useState("")

    // Add Driver 
    const AddVehicle = async () => {
        if (!VehicleImg) {
            return notify1("Please Select Vechicle Image")
        }
        if (!VehicleCategory) {
            return notify1("Please Select Vechicle Category")
        }
        if (!VehicleModel) {
            return notify1("Please Select Vechicle Model")
        }
        if (!VehicleNumber) {
            return notify1("Please Enter Vechicle Number")
        }
        if (!VehicleCapacity) {
            return notify1("Please Enter Vechicle Capacity")
        }
        if (!VehicleType) {
            return notify1("Please Select Vechicle Type")
        }
        if (!VehicleRCImg) {
            return notify1("Please Select Vechicle Image")
        }
        if (!VehicleInuranceImg) {
            return notify1("Please Select Vechicle Insurance Image")
        }
        if (!VehicleEmmision) {
            return notify1("Please Select Vechicle Emmision Image")
        }
        if (!VehicleOthDoc) {
            return notify1("Please Select Vechicle Other Documents")
        }
        try {
            const config = {
                url: "/admin/taxi/addvehicle",
                baseURL: "https://hayyyak.com/api",
                method: "post",
                headers: { "Content-Type": "multipart/form-data" },
                data: {
                    VehicleImg: VehicleImg,
                    VehicleCategory: VehicleCategory,
                    VehicleModel: VehicleModel,
                    VehicleNumber: VehicleNumber,
                    VehicleCapacity: VehicleCapacity,
                    VehicleType: VehicleType,
                    VehicleFuelType: VehicleFuelType,
                    VehicleRCImg: VehicleRCImg,
                    VehicleInuranceImg: VehicleInuranceImg,
                    VehicleEmmision: VehicleEmmision,
                    VehicleOthDoc: VehicleOthDoc,
                }
            }
            const res = await axios(config)
            if (res.status === 200) {
                notify(res.data.success)
                handleClose()
                GetVehicle()
                setVehicleImg("")
                setVehicleCategory("")
                setVehicleModel("")
                setVehicleNumber("")
                setVehicleCapacity("")
                setVehicleType("")
                setVehicleFuelType("")
                setVehicleRCImg("")
                setVehicleInuranceImg("")
                setVehicleEmmision("")
                setVehicleOthDoc("")
            }
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    // Get Driver
    const [GetVehicleData, setGetVehicleData] = useState([])
    const GetVehicle = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/admin/taxi/getvehicle"
            )
            if (res.status === 200) {
                setGetVehicleData(res.data.success)
                setnochangedata(res.data.success)
                setPagination(res.data.success)
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Edit Driver
    const [EditVehicleData, setEditVehicleData] = useState({})
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (id) => { setShow1(true); setEditVehicleData(id) }
    const EditVehicle = async () => {
        try {
            const config = {
                url: "/admin/taxi/editvehicle/" + EditVehicleData,
                baseURL: "https://hayyyak.com/api",
                method: "put",
                headers: { "Content-Type": "multipart/form-data" },
                data: {
                    VehicleImg: VehicleImg,
                    VehicleCategory: VehicleCategory,
                    VehicleModel: VehicleModel,
                    VehicleNumber: VehicleNumber,
                    VehicleCapacity: VehicleCapacity,
                    VehicleType: VehicleType,
                    VehicleFuelType: VehicleFuelType,
                    VehicleRCImg: VehicleRCImg,
                    VehicleInuranceImg: VehicleInuranceImg,
                    VehicleEmmision: VehicleEmmision,
                    VehicleOthDoc: VehicleOthDoc,
                }
            }
            const res = await axios(config)
            if (res.status === 200) {
                notify(res.data.success)
                handleClose1()
                GetVehicle()
                setVehicleImg("")
                setVehicleCategory("")
                setVehicleModel("")
                setVehicleNumber("")
                setVehicleCapacity("")
                setVehicleType("")
                setVehicleFuelType("")
                setVehicleRCImg("")
                setVehicleInuranceImg("")
                setVehicleEmmision("")
                setVehicleOthDoc("")
            }
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    // Delete Driver
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (id) => { setShow2(true); setDeleteVehicleData(id) }
    const [DeleteVehicleData, setDeleteVehicleData] = useState("");
    const DeleteVehicle = async () => {
        try {
            const config = {
                url: "admin/taxi/deletevehicle/" + DeleteVehicleData,
                baseURL: "https://hayyyak.com/api/",
                method: "delete",
                headers: { "Content-Type": "application/json" }
            }
            const res = await axios(config)
            if (res.status === 200) {
                notify(res.data.success)
                handleClose2()
                GetVehicle()
            }
        } catch (error) {
            notify2(error.response.data.error)
        }
    }

    // get vehicle model
    const [getVehicleModelData, setgetVehicleModelData] = useState([]);
    const GetVehicleModel = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/admin/taxi/getvehiclemodel"
            )
            if (res.status === 200) {
                setgetVehicleModelData(res.data.success)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        GetVehicle()
        GetVehicleModel()
    }, [])

    // Date Filter
    const [noschangedata, setnochangedata] = useState([]);
    const [fromd, setfromd] = useState("");
    const [tod, settod] = useState("");
    const searchDate = () => {
        if (!fromd) return notify1("Please select from date");
        if (!tod) return notify1("Please select to date");

        let std = fromd?.split("-");
        let entd = tod?.split("-");
        let startD = parseInt(std?.join(""));
        let endD = parseInt(entd?.join(""));
        let abc = noschangedata?.filter((ele) => {
            let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
            let amd = parseInt(abd?.join(""));
            return startD <= amd && endD >= amd;
        });
        setPagination(abc);
        setGetVehicleData(abc);
    };


    // Search
    const [SearchItem, setSearchItem] = useState("");

    //Pagination
    const [pagination, setPagination] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;
    const pageCount = Math.ceil(pagination?.length / usersPerPage);
    const changePage = (selected) => {
        setPageNumber(selected);
    };

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                closeOnClick
                pauseOnHover
                draggable
            />
            <Container fluid>
                <div className='d-flex justify-content-between align-items-center pt-4 pb-2'>
                    <p className="text-danger fs-4 fw-semibold">Vehicle List :</p>
                    <button className='pink-btn' onClick={handleShow}>Add Vehicle</button>
                </div>

                <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                    <div className="col-lg-2">
                        <label>From :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => setfromd(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <label>To :</label>
                        <Form.Control
                            type="date"
                            aria-describedby="basic-addon1"
                            onChange={(e) => settod(e.target.value)}
                        />
                    </div>

                    <div className="col-lg-2">
                        <button className="pink-btn" onClick={searchDate}>Submit</button>
                    </div>

                    <div className="input-group col-lg-3" style={{ width: 'auto', height: '35px', marginTop: '20px' }}>
                        <span class="input-group-text" id="basic-addon1"><BsSearch /></span>
                        <input type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-describedby="basic-addon1"
                            onChange={(e) => setSearchItem(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-1 export-btn">
                        <ReactHTMLTableToExcel
                            id="export-to-excel"
                            table="Export-table"
                            filename="bookedActivity"
                            sheet="bookedActivity"
                            buttonText="Export"
                        />
                    </div>
                </div>
                <div >
                    <Table responsive bordered className='admin-table-head' id='Export-table' >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Vehicle Image</th>
                                <th>Vehicle Category</th>
                                <th>Vehicle Model</th>
                                <th>Vehicle Number</th>
                                <th>Capacity</th>
                                <th>Vehicle Type</th>
                                <th>Vehicle Fuel Type</th>
                                <th>Vehicle RC</th>
                                <th>Vehicle Insurance</th>
                                <th>Vehicle Emissions</th>
                                <th>Vehicle Other Document</th>
                                <th>Vechicle Created Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {GetVehicleData?.slice(pagesVisited, pagesVisited + usersPerPage)?.map((item, i) => {
                                if (
                                    SearchItem === "" ||
                                    Object.values(item).some(
                                        value => String(value).toLowerCase().includes(SearchItem.toLowerCase())
                                    )
                                )
                                    return (<>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                                <a href={`https://hayyyak.com/VehicleList/${item?.VehicleImg}`} target="_blank" rel="noopener noreferrer">
                                                    <img src={`https://hayyyak.com/VehicleList/${item?.VehicleImg}`} alt="" style={{ width: '100px', height: '100px', imageRendering: 'pixelated' }} />
                                                </a>
                                            </td>
                                            <td>{item?.VehicleCategory}</td>
                                            <td>{item?.VehicleModel}</td>
                                            <td>{item?.VehicleNumber}</td>
                                            <td>{item?.VehicleCapacity}</td>
                                            <td>{item?.VehicleType}</td>
                                            <td>{item?.VehicleFuelType}</td>
                                            <td>
                                                <a href={`https://hayyyak.com/VehicleList/${item?.VehicleRCImg}`} target="_blank" rel="noopener noreferrer">
                                                    <img src={`https://hayyyak.com/VehicleList/${item?.VehicleRCImg}`} alt="" style={{ width: '100px', height: '100px', imageRendering: 'pixelated' }} />
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`https://hayyyak.com/VehicleList/${item?.VehicleInuranceImg}`} target="_blank" rel="noopener noreferrer">
                                                    <img src={`https://hayyyak.com/VehicleList/${item?.VehicleInuranceImg}`} alt="" style={{ width: '100px', height: '100px', imageRendering: 'pixelated' }} />
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`https://hayyyak.com/VehicleList/${item?.VehicleEmmision}`} target="_blank" rel="noopener noreferrer">
                                                    <img src={`https://hayyyak.com/VehicleList/${item?.VehicleEmmision}`} alt="" style={{ width: '100px', height: '100px', imageRendering: 'pixelated' }} />
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`https://hayyyak.com/VehicleList/${item?.VehicleOthDoc}`} target="_blank" rel="noopener noreferrer">
                                                    <img src={`https://hayyyak.com/VehicleList/${item?.VehicleOthDoc}`} alt="" style={{ width: '100px', height: '100px', imageRendering: 'pixelated' }} />
                                                </a>
                                            </td>
                                            <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                                            <td>
                                                <div className="d-flex gap-4 fs-4" style={{ cursor: 'pointer' }}>
                                                    <BiSolidEdit className='text-primary' onClick={() => handleShow1(item?._id)} />
                                                    <MdDelete className='text-danger' onClick={() => handleShow2(item?._id)} />
                                                </div>
                                            </td>
                                        </tr>
                                    </>)
                            })}

                        </tbody>
                    </Table>
                </div>
                <div className='my-3'>
                    <Stack spacing={2}>
                        <Pagination
                            count={pageCount}
                            color="primary"
                            onChange={(event, value) => {
                                changePage(value - 1)
                            }}
                        />
                    </Stack>
                </div>
            </Container>

            {/* Add Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Add Vehicle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Category :</label>
                            <Form.Select aria-label="Default select example"
                                className='vi_0'
                                onChange={(e) => setVehicleCategory(e.target.value)}
                            >
                                <option>Select Category</option>
                                {getVehicleModelData?.map((item) => {
                                    return (
                                        <option value={item?.vehiclecategory}>{item?.vehiclecategory}</option>
                                    )
                                })}
                            </Form.Select>
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold' >Vehicle Model :</label>
                            <Form.Select aria-label="Default select example"
                                className='vi_0'
                                onChange={(e) => setVehicleModel(e.target.value)}
                            >
                                <option>Select Model</option>
                                {getVehicleModelData?.filter((ele) => ele?.vehiclecategory == VehicleCategory)?.map((item) => {
                                    return (<>
                                        <option value={item?.vehicleModel}> {item?.vehicleModel}</option>
                                    </>)
                                })}
                            </Form.Select>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Number :</label>
                            <input type="text" placeholder='KA 26 W 2353' className="vi_0" onChange={(e) => setVehicleNumber(e.target.value)} />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Capacity :</label>
                            <input type="text" placeholder='capacity' className="vi_0" onChange={(e) => setVehicleCapacity(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Ac / Non-Ac :</label>
                            <Form.Select aria-label="Default select example"
                                className='vi_0'
                                onChange={(e) => setVehicleType(e.target.value)}
                            >
                                <option>Select</option>
                                <option value="AC">AC</option>
                                <option value="NON-AC">NON-AC</option>
                            </Form.Select>
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Fuel Type:</label>
                            <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setVehicleFuelType(e.target.value)}>
                                <option>Open this select menu</option>
                                <option value="Petrol">Petrol</option>
                                <option value="Diesel">Diesel</option>
                                <option value="Electric">Electric</option>
                            </Form.Select>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold' htmlFor='upload'>Vehicle RC :</label>
                            <input type="file" id='upload' accept='image/*' className="vi_0" onChange={(e) => setVehicleRCImg(e.target.files[0])} />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold' htmlFor='upload'>Vehicle Insurance:</label>
                            <input type="file" id='upload' accept='image/*' className="vi_0" onChange={(e) => setVehicleInuranceImg(e.target.files[0])} />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold' htmlFor='upload'>Vehicle Emissions:</label>
                            <input type="file" id='upload' accept='image/*' className="vi_0" onChange={(e) => setVehicleEmmision(e.target.files[0])} />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold' htmlFor='upload'>Vehicle Other Document:</label>
                            <input type="file" id='upload' accept='image/*' className="vi_0" onChange={(e) => setVehicleOthDoc(e.target.files[0])} />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold' htmlFor='upload'>Vehicle Image :</label>
                            <input type="file" id='upload' accept='image/*' className="vi_0" onChange={(e) => setVehicleImg(e.target.files[0])} />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={AddVehicle}>
                        Add Vehicle
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal*/}
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger'>Edit Vehicle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Category :</label>
                            <Form.Select aria-label="Default select example"
                                className='vi_0'
                                onChange={(e) => setVehicleCategory(e.target.value)}
                            >
                                <option>Select Category</option>
                                {getVehicleModelData?.map((item) => {
                                    return (
                                        <option value={item?.vehiclecategory}>{item?.vehiclecategory}</option>
                                    )
                                })}
                            </Form.Select>
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold' >Vehicle Model :</label>
                            <Form.Select aria-label="Default select example"
                                className='vi_0'
                                onChange={(e) => setVehicleModel(e.target.value)}
                            >
                                {getVehicleModelData?.filter((ele) => ele?.vehiclecategory == VehicleCategory)?.map((item) => {
                                    return (<>
                                        <option>Select Model</option>
                                        <option value={item?.vehicleModel}> {item?.vehicleModel}</option>
                                    </>)
                                })}
                            </Form.Select>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Number :</label>
                            <input type="text" placeholder='KA 26 W 2353' className="vi_0" onChange={(e) => setVehicleNumber(e.target.value)} />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Capacity :</label>
                            <input type="text" placeholder='capacity' className="vi_0" onChange={(e) => setVehicleCapacity(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold'>Ac / Non-Ac :</label>
                            <Form.Select aria-label="Default select example"
                                className='vi_0'
                                onChange={(e) => setVehicleType(e.target.value)}
                            >
                                <option>Select</option>
                                <option value="AC">AC</option>
                                <option value="NON-AC">NON-AC</option>
                            </Form.Select>
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold'>Vehicle Fuel Type:</label>
                            <Form.Select aria-label="Default select example" className="vi_0" onChange={(e) => setVehicleFuelType(e.target.value)}>
                                <option>Open this select menu</option>
                                <option value="Petrol">Petrol</option>
                                <option value="Diesel">Diesel</option>
                                <option value="Electric">Electric</option>
                            </Form.Select>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold' htmlFor='upload'>Vehicle RC :</label>
                            <input type="file" id='upload' accept='image/*' className="vi_0" onChange={(e) => setVehicleRCImg(e.target.files[0])} />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold' htmlFor='upload'>Vehicle Insurance:</label>
                            <input type="file" id='upload' accept='image/*' className="vi_0" onChange={(e) => setVehicleInuranceImg(e.target.files[0])} />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold' htmlFor='upload'>Vehicle Emissions:</label>
                            <input type="file" id='upload' accept='image/*' className="vi_0" onChange={(e) => setVehicleEmmision(e.target.files[0])} />
                        </div>
                        <div className="col-lg-6">
                            <label className='fw-bold' htmlFor='upload'>Vehicle Other Document:</label>
                            <input type="file" id='upload' accept='image/*' className="vi_0" onChange={(e) => setVehicleOthDoc(e.target.files[0])} />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-6">
                            <label className='fw-bold' htmlFor='upload'>Vehicle Image :</label>
                            <input type="file" id='upload' accept='image/*' className="vi_0" onChange={(e) => setVehicleImg(e.target.files[0])} />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleClose1}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={EditVehicle}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Modal */}

            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            style={{ width: "80px", height: "80px", borderRadius: "5px", marginBottom: '10px' }}
                            src="./img/delete-btn.png"
                            alt=""
                        />
                        <h4 className='fw-bold text-dark mb-2'>Are You Sure</h4>
                        <p>This data will be removed permanently</p>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="success" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button onClick={DeleteVehicle} variant="danger"><FontAwesomeIcon icon={faCancel} className=' me-2' />Delete</Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default AdminVehicle
