import React, { useEffect, useState } from 'react'
import { Container, Table, Button, Form, Modal, } from 'react-bootstrap'
import { BsFillBusFrontFill } from 'react-icons/bs'
import { FaHotel, FaMapMarkedAlt } from 'react-icons/fa'
import HikingIcon from '@mui/icons-material/Hiking';
import axios from 'axios';
import moment from 'moment';
import { AiFillEye } from 'react-icons/ai';

function Vendor_Tour_Dashboard() {
    const TourVendor = JSON.parse(sessionStorage.getItem("TourId"))
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => { setShow1(true); };

    const [bookedTourpackages, setbookedTourpackages] = useState([]);
    const [Traveler, setTraveler] = useState({});
    const getbookedTourPackages = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/admin/getbookedtourpackages"
            );
            if (res.status === 200) {
                setbookedTourpackages(res.data.bookedtourpackages);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getbookedTourPackages()
    }, [])

    return (
        <Container >
            <div className="row gap-2 align-items-center justify-content-center mt-3">
                <div
                    className="col-lg-5 text-light p-3 mb-3"
                    style={{
                        backgroundColor: "rgb(171 155 21)",
                        borderRadius: "10px",
                    }}
                >
                    <h6 className="fs-4 fw-bold"> Tour Package</h6>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <FaMapMarkedAlt className="fs-2" />
                        <span className="fs-2 fw-bold ">{bookedTourpackages?.filter((ele) => ele?.packageId?.TourId === TourVendor?._id)?.length}</span>
                    </div>
                    <p>{bookedTourpackages?.filter((ele) => ele?.packageId?.TourId === TourVendor?._id)?.length} Bookings of Tour Package</p>
                </div>
            </div>
            <p className='fw-bold text-danger fs-3 mb-2'>Recent Booking List :</p>
            <div>
                <Table responsive bordered className="admin-table-head" id="Export-table">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>User Name</th>
                            <th>User Number</th>
                            <th>User Email</th>
                            <th>Trip Name</th>
                            <th>Booking Date</th>
                            <th>Trip Duration</th>
                            <th>Check In Date</th>
                            <th>Check Out Date</th>
                            <th>Traveller Details</th>
                            <th>Location</th>
                            <th>Total Person</th>
                            <th>Actual Price</th>
                            <th>Discount(%)</th>
                            <th>Discount Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookedTourpackages?.filter((ele) => ele?.packageId?.TourId === TourVendor?._id)?.slice(0, 5)?.map((item, i) => {
                            return (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{item?.userId?.Fname} {item?.userId?.Lname}</td>
                                    <td>{item?.userId?.Mobile}</td>
                                    <td>{item?.userId?.Email}</td>
                                    <td>{item?.packageId?.tripname}</td>
                                    <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                                    <td>{item?.packageId?.tripduration} <b>Days</b> / {item?.packageId?.tripnight} <b>Night</b></td>
                                    <td>{item?.checkindate}</td>
                                    <td>{item?.CheckOutDates}</td>
                                    <td>
                                        <AiFillEye
                                            style={{ cursor: 'pointer' }}
                                            className="text-danger fs-5"
                                            onClick={() => {
                                                setTraveler(item)
                                                handleShow1()
                                            }} />
                                    </td>
                                    <td>{item?.packageId?.city}</td>
                                    <td>
                                        Adult:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {item?.adultquantity}
                                        </span>{" "}
                                        ,<br />
                                        Children:{" "}
                                        <span style={{ color: "green" }}>
                                            {item?.childrenquantity}
                                        </span>{" "}
                                        ,<br />
                                        Infant:{" "}
                                        <span style={{ color: "green" }}>
                                            {item?.petsquantity}
                                        </span>
                                    </td>
                                    <td>
                                        Adult:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {" "}
                                            ₹ {item?.packageId?.tripcost}
                                        </span>{" "}
                                        ,<br />
                                        Children:{" "}
                                        <span style={{ color: "green" }}>
                                            {" "}
                                            ₹ {item?.packageId?.childrencost}
                                        </span>{" "}
                                        ,<br />
                                        Infant:{" "}
                                        <span style={{ color: "green" }}>
                                            {" "}
                                            ₹ {item?.packageId?.petscost}
                                        </span>
                                    </td>
                                    <td>
                                        Adult:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {" "}
                                            {item?.packageId?.tripdiscount} %
                                        </span>{" "}
                                        ,<br />
                                        Children:{" "}
                                        <span style={{ color: "green" }}>
                                            {" "}
                                            {item?.packageId?.childrendiscount} %
                                        </span>{" "}
                                        ,<br />
                                        Infant:{" "}
                                        <span style={{ color: "green" }}>
                                            {" "}
                                            {item?.packageId?.petdiscount} %
                                        </span>
                                    </td>
                                    <td>
                                        Adult:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {" "}
                                            ₹ {item?.adultprice}{" "}
                                        </span>{" "}
                                        ,<br />
                                        Children:{" "}
                                        <span style={{ color: "green" }}>
                                            ₹ {item?.childrenprice}{" "}
                                        </span>{" "}
                                        ,<br />
                                        Infant:{" "}
                                        <span style={{ color: "green" }}>
                                            {" "}
                                            ₹ {item?.petsprice}{" "}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>

            {/* Traveller Model */}
            <Modal
                show={show1}
                onHide={handleClose1}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <p className="fw-bold">Total Trvelers : <span className="text-danger">{Traveler?.TravelersData?.length}</span></p>
                    <Table bordered className="sdfsd-table-head">
                        <thead>
                            <tr>
                                <th width="20%">S.No</th>
                                <th>Traveler Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Traveler?.TravelersData?.map((item, i) => (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{item?.TravellerFirstName} {item?.TravellerLastName}</td>
                                </tr>
                            ))}
                            <tr>
                                <td className="fw-bold">Contact Details :</td>
                                <td>
                                    Email:{" "}
                                    <span style={{ color: "green", fontSize: "18px" }}>
                                        {Traveler?.useremail}
                                    </span>{" "}
                                    ,<br />
                                    Number:{" "}
                                    <span style={{ color: "green" }}>
                                        {Traveler?.userphoneno}
                                    </span>{" "}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose1}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    )
}

export default Vendor_Tour_Dashboard