import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect } from "react";
import { Container, Form, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import { Label } from "@mui/icons-material";
import parse from "html-react-parser"

export const UpdateProfile = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  ////////////////

  const steps = ["Update Property 1", "Update Property 2"];

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };



  const StayVendor = JSON.parse(sessionStorage.getItem("Stays"));

  const [selectedAmenities, setSelectedAmenities] = useState([]);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAmenities(value);
  };

  const [seleceCategory, setseleceCategory] = useState([]);

  const handleSelectCategory = (event) => {
    const {
      target: { value },
    } = event;
    setseleceCategory(value);
  };

  // Add Gallery Image
  const [imageURL2, setimageURL2] = useState(null);
  const [Image, setImage] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setimageURL2(URL.createObjectURL(event.target.files[0]));
    }
  };
  const AddGalleryImage = () => {
    if (!Image) {
      return alert("Please, Select Image");
    }

    const imageExists = galleryImages.find((img) => img?.imgUrl === Image);

    if (imageExists) {
      return alert("Image already exists in the gallery.");
    }

    const obj = {
      image: Image,
      imgUrl: imageURL2,
    };

    setGalleryImages([...galleryImages, obj]);
    setImage("")
  };
  const removeItem2 = (val) => {
    const updatedGallery = galleryImages.filter((item, index) => index !== val);
    setGalleryImages(updatedGallery);
  };

  // FAQ
  const [allFaq, setallFaq] = useState([]);
  const [question, setquestion] = useState()
  const [answer, setanswer] = useState()
  const AddFaq = () => {
    const FaqExists = allFaq?.filter((ele) =>
      ele.question === question &&
      ele.answer === answer
    )
    if (!FaqExists) {
      return alert("FAQ Details is already exists")
    }
    const obj = {
      question: question,
      answer: answer
    }
    setallFaq([...allFaq, obj])
  }
  const removeFAQ = (val) => {
    const updateFaq = allFaq.filter((item, index) => index !== val);
    setallFaq(updateFaq)
  }

  //Location
  const [Country1, setCountry1] = useState("")
  const [State1, setState1] = useState("")
  const [City1, setCity1] = useState("")
  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(Country1);
  const CityList = City.getCitiesOfState(Country1, State1);


  const [Aadhar, setAadhar] = useState("");
  const [PanCard, setPanCard] = useState("");
  const [Gst, setGst] = useState("");
  const [GstDocument, setGstDocument] = useState("");
  const [PropartyDoc, setPropartyDoc] = useState("");
  const [PropartyName, setPropartyName] = useState("");
  const [PropartyAddress, setPropartyAddress] = useState("");
  const [location, setlocation] = useState("");
  const [StartingPrice, setStartingPrice] = useState();
  const [Totalrooms, setTotalrooms] = useState();
  const [Property, setProperty] = useState("");
  const [Checkin, setCheckin] = useState("");
  const [CheckOut, setCheckOut] = useState("");
  const [Description, setDescription] = useState("");
  const [termsandcondition, settermsandcondition] = useState("");
  const [Suggesition, setSuggesition] = useState("");
  const [Propartyfullimage, setPropartyfullimage] = useState("");
  const [NearByLoc, setNearByLoc] = useState("");
  const [HouseRules, setHouseRules] = useState("");
  const [ThingToDo, setThingToDo] = useState("");

  const formdata = new FormData();
  const AddStaysDetails = async () => {
    try {
      formdata.append("aadhar", Aadhar);
      formdata.append("pancard", PanCard);
      formdata.append("gst", Gst);
      formdata.append("gstdocument", GstDocument);
      formdata.append("propartydoc", PropartyDoc);
      formdata.append("propartyname", PropartyName);
      formdata.append("propartyaddress", PropartyAddress);
      formdata.append("location", location);
      formdata.append("startingprice", StartingPrice);
      formdata.append("totalroom", Totalrooms);
      formdata.append("porparty", Property);
      formdata.append("checkin", Checkin);
      formdata.append("checkout", CheckOut);
      formdata.append("description", Description);
      formdata.append("termsandcondition", termsandcondition);
      formdata.append("suggesition", Suggesition);
      formdata.append("propartfullimage", Propartyfullimage);
      formdata.append("country", Country1);
      formdata.append("state", State1);
      formdata.append("city", City1);
      formdata.append("Nearbyplaces", NearByLoc);
      formdata.append("Houserules", HouseRules);
      formdata.append("ThingToDo", ThingToDo);
      formdata.append("faq", JSON.stringify(allFaq));
      formdata.append("vendorId", StayVendor?._id);
      formdata.append("Amenities", JSON.stringify(selectedAmenities));
      formdata.append("StaysCategory", JSON.stringify(seleceCategory));
      const config = {
        url: "/vendor/stays/staysdetails",
        baseURL: "https://hayyyak.com/api",
        method: "post",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      const response = await axios(config);
      if (response.status === 200) {
        console.log("response", response);
        for (let i = 0; i < galleryImages.length; i++) {
          const config = {
            url: "/vendor/stays/galleryimageupload",
            method: "put",
            baseURL: "https://hayyyak.com/api",
            headers: { "content-type": "multipart/form-data" },
            data: {
              productId: response.data.data?._id,
              image: galleryImages[i]?.image,
            },
          };
          await axios(config);
        }
        alert("Updated Successfully");
        window.location.assign("/vendor_add_hotel")
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get Stays Category
  const [StayCategory, setStayCategory] = useState([]);
  const getStayCategory = async () => {
    axios
      .get("https://hayyyak.com/api/admin/stays/getstaycategory")
      .then(function (res) {
        console.log(res.data);
        setStayCategory(res.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // get Stay Amenities
  const [StayAmenities, setStayAmenities] = useState([]);
  const getStayAmenities = async () => {
    axios
      .get("https://hayyyak.com/api/admin/stays/getstayamenities")
      .then(function (response) {
        setStayAmenities(response.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getStayCategory();
    getStayAmenities();
  }, []);



  return (
    <div>
      <Container>
        <div>
          <p className="text-danger fs-4 fw-semibold mb-4 mt-3">
            Update Your Details:
          </p>
        </div>
        <Box sx={{ width: "100%" }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {allStepsCompleted() ? (
              <React.Fragment>
                <Typography sx={{ mt: 4, mb: 1 }}>
                  <div className="d-flex justify-content-center">
                    <img
                      src="./img/check.gif"
                      alt=""
                      className="text-center"
                      style={{
                        width: "400px",
                        height: "400px",
                        imageRendering: "pixelated",
                        objectFit: "none",
                      }}
                    />
                  </div>

                  <p
                    style={{
                      textShadow: "10px 3px 18px #000",
                      fontSize: "24px",
                      textAlign: "center",
                    }}
                  >
                    All steps completed you will receive a response from the
                    admin within 24 hours once all necessary steps have been
                    completed."
                  </p>
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Back</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep === 0 ? (
                  <>
                    <Typography sx={{ mt: 0, mb: 1, py: 1 }}>
                      <div>
                        <h1 class="display-6 text-center mb-3">
                          Document <span className="text-danger">Details</span>
                        </h1>
                        {/* <div className="row mb-4">
                          <div className="col-lg-3">
                            <label className="fw-bold">First Name :</label>
                            <input
                              type="text"
                              value={Fname}
                              className="form-control login-input"
                              placeholder={StayVendor?.Fname}
                              onChange={(e) => setFname(e.target.value)}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label className="fw-bold">Last Name:</label>
                            <input
                              value={Lname}
                              type="text"
                              className="form-control login-input"
                              placeholder={StayVendor?.Lname}
                              onChange={(e) => setLname(e.target.value)}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label className="fw-bold">Mobile Number:</label>
                            <input
                              value={mobilenumber}
                              type="text"
                              className="form-control login-input"
                              placeholder={StayVendor?.Mobile}
                              onChange={(e) => setmobilenumber(e.target.value)}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label className="fw-bold">Email :</label>
                            <input
                              value={emailId}
                              type="text"
                              className="form-control login-input"
                              placeholder={StayVendor?.Email}
                              onChange={(e) => setemailId(e.target.value)}
                            />
                          </div>
                        </div> */}
                        <div className="row mb-4">
                          <div className="col-lg-3">
                            <label className="fw-bold">Aadhar card :</label>
                            <input
                              type="file"
                              multiple
                              className="form-control login-input"
                              onChange={(e) => setAadhar(e.target.files[0])}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label className="fw-bold">Pan card :</label>
                            <input
                              type="file"
                              multiple
                              className="form-control login-input"
                              onChange={(e) => setPanCard(e.target.files[0])}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label className="fw-bold">GST Number:</label>
                            <input
                              type="text"
                              placeholder="Gst number..."
                              className="form-control login-input"
                              onChange={(e) => setGst(e.target.value)}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label className="fw-bold">GST Document :</label>
                            <input
                              type="file"
                              multiple
                              className="form-control login-input"
                              onChange={(e) =>
                                setGstDocument(e.target.files[0])
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-lg-3">
                            <label className="fw-bold">
                              Property Document :
                            </label>
                            <input
                              type="file"
                              className="form-control login-input"
                              onChange={(e) =>
                                setPropartyDoc(e.target.files[0])
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Typography>
                  </>
                ) : (
                  <>
                    {activeStep === 1 ? (
                      <>
                        {/* Display errors */}

                        <Typography sx={{ mt: 0, mb: 1, py: 1 }}>
                          <div>
                            <h1 class="display-6 text-center mb-3">
                              Update{" "}
                              <span className="text-danger">Property</span>
                            </h1>
                            <div className="row mb-4">
                              <div className="col-lg-3">
                                <label className="fw-bold">
                                  Property Name:
                                </label>
                                <input
                                  value={PropartyName}
                                  type="text"
                                  className="form-control login-input"
                                  placeholder="Enter Property Name"
                                  onChange={(e) =>
                                    setPropartyName(e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-lg-3">
                                <label className="fw-bold">
                                  Property Address :
                                </label>
                                <input
                                  value={PropartyAddress}
                                  type="text"
                                  className="form-control login-input"
                                  placeholder="Enter Address"
                                  onChange={(e) =>
                                    setPropartyAddress(e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-lg-3">
                                <label className="fw-bold">
                                  Property Location (Link):
                                </label>
                                <input
                                  value={location}
                                  type="text"
                                  className="form-control login-input"
                                  placeholder="location........"
                                  onChange={(e) => setlocation(e.target.value)}
                                />
                              </div>
                              <div className="col-lg-3">
                                <label className="fw-bold">
                                  Starting Price :
                                </label>
                                <input
                                  value={StartingPrice}
                                  type="text"
                                  className="form-control login-input"
                                  placeholder="Enter Satrting Price"
                                  onChange={(e) =>
                                    setStartingPrice(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="row mb-4">
                              <div className="col-lg-3">
                                <label className="fw-bold">
                                  Country Name:
                                </label>

                                <select

                                  className="form-control login-input"
                                  placeholder="Enter Property Name"
                                  onChange={(e) => setCountry1(e.target.value)}
                                >
                                  <option>Select Country</option>
                                  {CountryList.map((Country) => (
                                    <option value={Country.isoCode}>
                                      {Country.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-lg-3">
                                <label className="fw-bold">
                                  State Name :
                                </label>
                                <select

                                  className="form-control login-input"
                                  placeholder="Enter Property Name"
                                  onChange={(e) => setState1(e.target.value)}
                                >
                                  <option>Select State</option>
                                  {StateList.map((state) => (
                                    <option value={state.isoCode}>
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-lg-3">
                                <label className="fw-bold">
                                  City Name:
                                </label>
                                <select

                                  className="form-control login-input"
                                  placeholder="Enter Property Name"
                                  onChange={(e) => setCity1(e.target.value)}
                                >
                                  <option>Select City</option>
                                  {CityList.map((city) => (
                                    <option value={city.isoCode}>
                                      {city.name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                            </div>

                            <div className="row mb-4 ">
                              <div className="col-lg-3">
                                <div>
                                  <label className="fw-bold">Amenities :</label>
                                  <FormControl
                                    sx={{ m: 1, width: 245, height: 43 }}
                                  >
                                    <InputLabel id="demo-multiple-checkbox-label">
                                      Amenities
                                    </InputLabel>
                                    <Select
                                      labelId="demo-multiple-checkbox-label"
                                      id="demo-multiple-checkbox"
                                      multiple
                                      value={selectedAmenities}
                                      onChange={handleSelectChange}
                                      input={
                                        <OutlinedInput label="Amenities" />
                                      }
                                      renderValue={(selected) => selected.map((amenity) => amenity.AmenitiName).join(", ")}
                                      MenuProps={MenuProps}
                                    >
                                      {StayAmenities.map((amenity) => (
                                        <MenuItem key={amenity._id} value={amenity}>
                                          <Checkbox checked={selectedAmenities.some((selected) => selected._id === amenity._id)} />
                                          <ListItemText primary={amenity.AmenitiName} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>

                              <div className="col-lg-3">
                                <label className="fw-bold">Category :</label>
                                <FormControl
                                  sx={{ m: 1, width: 245, height: 43 }}
                                >
                                  <InputLabel id="demo-multiple-checkbox-label">
                                    Category
                                  </InputLabel>
                                  <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={seleceCategory}
                                    onChange={handleSelectCategory}
                                    input={<OutlinedInput label="Category" />}
                                    renderValue={(selected) => selected.map((category) => category.Category).join(", ")}
                                    MenuProps={MenuProps}
                                  >
                                    {StayCategory.map((category) => (
                                      <MenuItem key={category?._id} value={category}>
                                        <Checkbox checked={seleceCategory?.some((selected) => selected._id === category._id)} />
                                        <ListItemText primary={category?.Category} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                              <div className="col-lg-3">
                                <label className="fw-bold">Total Rooms:</label>
                                <input
                                  value={Totalrooms}
                                  type="text"
                                  className="form-control login-input"
                                  placeholder="Enter total rooms"
                                  onChange={(e) =>
                                    setTotalrooms(e.target.value)
                                  }
                                />
                              </div>

                              <div className="col-lg-3">
                                <label className="fw-bold">
                                  Single / Multiple Property :
                                </label>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="login-input"
                                  onChange={(e) => setProperty(e.target.value)}
                                >
                                  <option>Select </option>
                                  <option value="Single Property">
                                    Single Property
                                  </option>
                                  <option value="Multiple Property">
                                    Multiple Property
                                  </option>
                                </Form.Select>
                              </div>
                            </div>
                            <div className="mb-4 row">
                              <div className="col-lg-3">
                                <label className="fw-bold">
                                  Check In Time:
                                </label>
                                <input
                                  type="time"
                                  className="form-control login-input"
                                  onChange={(e) => setCheckin(e.target.value)}
                                />
                              </div>
                              <div className="col-lg-3">
                                <label className="fw-bold">
                                  Check Out Time :
                                </label>
                                <input
                                  type="time"
                                  className="form-control login-input"
                                  onChange={(e) => setCheckOut(e.target.value)}
                                />
                              </div>

                              <div className="col-lg-6 mb-2">
                                <label className="fw-bold">
                                  Property Descritpion:
                                </label>
                                <CKEditor
                                  data={Description}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setDescription(data);
                                  }}
                                  editor={ClassicEditor}
                                />
                              </div>

                              <div className="col-lg-6 mb-2 ">
                                <label className="fw-bold">
                                  Terms & Conditions:
                                </label>
                                <CKEditor
                                  data={termsandcondition}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    settermsandcondition(data);
                                  }}
                                  editor={ClassicEditor}
                                />
                              </div>
                              <div className="col-lg-6 mb-2">
                                <label className="fw-bold">Suggestion:</label>
                                <CKEditor
                                  data={Suggesition}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setSuggesition(data);
                                  }}
                                  editor={ClassicEditor}
                                />
                              </div>
                              <div className="col-lg-6 mb-2 ">
                                <label className="fw-bold">
                                  Near By Location:
                                </label>
                                <CKEditor
                                  data={NearByLoc}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setNearByLoc(data);
                                  }}
                                  editor={ClassicEditor}
                                />
                              </div>
                              <div className="col-lg-6 mb-2">
                                <label className="fw-bold">House Rules:</label>
                                <CKEditor
                                  data={HouseRules}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHouseRules(data);
                                  }}
                                  editor={ClassicEditor}
                                />
                              </div>
                              <div className="col-lg-6 mb-2">
                                <label className="fw-bold">Things To Do:</label>
                                <CKEditor
                                  data={ThingToDo}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setThingToDo(data);
                                  }}
                                  editor={ClassicEditor}
                                />
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <div>
                                    <label className="fw-bold">
                                      Property Full Image :
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      className="form-control login-input"
                                      onChange={(e) =>
                                        setPropartyfullimage(e.target.files[0])
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mb-4">

                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <div className="d-flex gap-5 align-items-end">
                                    <div>
                                      <label className="fw-bold">
                                        Gallery Images:
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        className="form-control login-input"
                                        onChange={(e) => {
                                          onImageChange2(e);
                                          setImage(e.target.files[0]);

                                        }}
                                      />
                                    </div>
                                    <button
                                      className="pink-btn"
                                      style={{ height: "38px" }}
                                      onClick={AddGalleryImage}
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                                <div>
                                  <Table className="sdfsd-table-head">
                                    <thead>
                                      <tr>
                                        <th>S.No</th>
                                        <th>Image</th>
                                        <th>action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {galleryImages?.map((item, i) => {
                                        return (
                                          <tr>
                                            <th>{i + 1}</th>
                                            <th>
                                              <img
                                                src={item?.imgUrl}
                                                alt=""
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                }}
                                              />
                                            </th>
                                            <th>
                                              <MdDelete
                                                onClick={() => removeItem2(i)}
                                                className="text-danger fs-3"
                                              />
                                            </th>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <label className="fw-bold" >FAQ :</label>
                                <div className="mb-2">
                                  <input
                                    type="text"
                                    placeholder="Questions...??"
                                    multiple
                                    className="form-control login-input"
                                    onChange={(e) => {
                                      setquestion(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="mb-2">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                      placeholder: 'Answer...!!!',
                                    }}
                                    data={answer}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setanswer(data);
                                    }}
                                  />
                                </div>
                                <button
                                  className="pink-btn m-auto mb-2"
                                  style={{ height: "38px" }}
                                  onClick={AddFaq}
                                >
                                  Add Faq
                                </button>

                                <div>
                                  <Table bordered className="sdfsd-table-head">
                                    <thead>
                                      <tr>
                                        <th>Question / Answer</th>
                                        <th>action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {allFaq?.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>
                                              <div>
                                                <p className="fw-bold">Q : {item?.question}?</p>
                                                <p>{parse(`<div>A : ${item?.answer}</div>`)}</p>
                                              </div>
                                            </td>
                                            <th>
                                              <MdDelete
                                                onClick={() => removeFAQ(i)}
                                                className="text-danger fs-5"
                                              />
                                            </th>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Typography>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    style={{ background: "green", color: "#fff" }}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    style={{ background: "#ffc107", color: "#000" }}
                    onClick={handleNext}
                    sx={{ mr: 1 }}
                  >
                    Next
                  </Button>
                  {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                      <Typography
                        variant="caption"
                        sx={{ display: "inline-block" }}
                      >
                        Step {activeStep + 1} already completed
                      </Typography>
                    ) : (
                      <button
                        className="pink-btn"
                        onClick={() => {
                          completedSteps() === totalSteps() - 1
                            ? AddStaysDetails()
                            : handleComplete();
                        }}
                      >
                        {completedSteps() === totalSteps() - 1
                          ? "Submit"
                          : "Complete Step"}
                      </button>
                    ))}
                </Box>
              </React.Fragment>
            )}
          </div>
        </Box>

        {/* <div className='mb-5'>
                    <button className='pink-btn'>Submit</button>
                </div> */}
      </Container>
    </div>
  );
};
