import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment/moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Container, Form, Modal, Table } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import ReactPaginate from "react-paginate";
import parse from "html-react-parser";
import { AiFillEye } from "react-icons/ai";
import { Link, useNavigate } from 'react-router-dom';
import { Pagination, Stack } from "@mui/material";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function VendorBookedActivity() {

    const ActivityVendor = JSON.parse(sessionStorage.getItem("activityvendor"))

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (item) => {
        setShow(true);
        setdata(item);
    };
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => { setShow1(true); };

    const [bookedActivity, setbookedactivity] = useState([]);
    const [Traveler, setTraveler] = useState({});
    const GetBookedActivity = async () => {
        try {
            const res = await axios.get(
                "https://hayyyak.com/api/customer/activity/getbookedactivity"
            );
            if (res.status === 200) {
                setbookedactivity(res.data.bookedActivity);
                setnochangedata(res.data.bookedActivity);
                // setPagination(res.data.bookedActivity);
            }
        } catch (error) {
            console.log(error);
        }
    };
    console.log("bookedActivity", bookedActivity);

    // Delete
    const [Data, setdata] = useState("");
    const DeleteBookedActivity = async () => {
        try {
            const config = {
                url: "customer/activity/daletebookedactivity/" + Data,
                baseURL: "https://hayyyak.com/api/",
                method: "DELETE",
                Headers: { "Content-Type": "application-json" },
            };
            await axios(config).then((res) => {
                if (res.status === 200) {
                    alert(res.data.success);
                    handleClose();
                    GetBookedActivity();
                }
            });
        } catch (error) {
            alert(error.response.data.error);
        }
    };

    useEffect(() => {
        GetBookedActivity();
    }, []);



    // Date Filter
    const [noschangedata, setnochangedata] = useState([]);
    const [fromd, setfromd] = useState("");
    const [tod, settod] = useState("");
    const searchDate = () => {
        if (!fromd) return alert("Please select from date");
        if (!tod) return alert("Please select to date");

        let std = fromd?.split("-");
        let entd = tod?.split("-");
        let startD = parseInt(std?.join(""));
        let endD = parseInt(entd?.join(""));
        let abc = noschangedata?.filter((ele) => {
            let abd = moment(ele?.createdAt).format("YYYY-MM-DD").split("-");
            let amd = parseInt(abd?.join(""));
            return startD <= amd && endD >= amd;
        });
        // setPagination(abc);
        setbookedactivity(abc);
    };

    // Search
    const [SearchItem, setSearchItem] = useState("");
    // Apply search filter
    const filteredActivities = bookedActivity.filter(item =>
        SearchItem === "" ||
        (Object.values(item.userId).some(value =>
            String(value).toLowerCase().includes(SearchItem.toLowerCase())
        ) ||
            (item?.Title && item?.Title.toLowerCase().includes(SearchItem.toLowerCase())))
    );

    // Paginate the filtered dataset
    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;

    const changePage = (selected) => {
        setPageNumber(selected);
    };

    // Apply the filter first
    const filteredActivitiesForVendor = filteredActivities.filter(
        (ele) => ele?.ActivityId?.actiitiyid === ActivityVendor?._id
    );

    const pageCount = Math.ceil(filteredActivitiesForVendor.length / usersPerPage);
    const paginatedActivities = filteredActivitiesForVendor.slice(pagesVisited, pagesVisited + usersPerPage);

    const exportAllData = () => {
        const dataToExport = filteredActivitiesForVendor?.map(item => ({
            UserName: `First Name : ${item?.userId?.Fname} Last Name : ${item?.userId?.Lname}`,
            UserEmail: `Email : ${item?.userId?.Email}`,
            UserNumber: `Number : ${item?.userId?.Mobile}`,
            ActivityName: `Acitivity Name : ${item?.Title}`,
            Location: `Location : ${item?.location}`,
            Country: `Country : ${item?.country}`,
            State: `State : ${item?.state}`,
            City: `City : ${item?.city}`,
            BookingDate: `Booking Date : ${moment(item?.checkindate).format("DD/MM/YYYY")}`,
            // TravellerDetails: `First Name: ${item?.TravellerFirstName}, Last Name: ${item?.TravellerLastName}`,
            TravellersNumbers: `Adult: ${item?.adultquantity}, Children: ${item?.childrenquantity}`,
            Price: `Adult : ${(item?.adultprice) / (1 - item?.adultdiscount / 100)}, Children : ${(item?.childrenprice) / (1 - item?.childrendiscount / 100)}`,
            Discount: `Adult Discount: ${item?.adultdiscount}%, Children Discount: ${item?.childrendiscount}%`,
            DiscountPrice: `Adult Discount Price : ${item?.adultprice}, Children Discount Pirce : ${item?.childrenprice}`,
            TotalPrice: `Total Price : ${(item?.adultprice) + (item?.childrenprice)}`
        }));
        exportToExcel(dataToExport);
    }
    const exportToExcel = (dataToExport) => {
        const csv = dataToExport.map(row => Object.values(row).join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "BookedAcitivity.csv";
        link.click();
    };

    return (
        <>
            <div>
                <Container fluid>
                    <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
                        <p className="text-danger fs-4 fw-semibold">Booked Activity List :</p>
                        {/* <button className='pink-btn' onClick={handleShow}>Add Package</button> */}
                    </div>

                    <div className="row p-2 align-items-end justify-content-around mb-3 nbjhasd_0">
                        <div className="col-lg-2">
                            <label>From :</label>
                            <Form.Control type="date"
                                aria-describedby="basic-addon1"
                                onChange={(e) => setfromd(e.target.value)}
                            />
                        </div>

                        <div className="col-lg-2">
                            <label>To :</label>
                            <Form.Control type="date"
                                aria-describedby="basic-addon1"
                                onChange={(e) => settod(e.target.value)}
                            />
                        </div>

                        <div className="col-lg-2">
                            <button className="pink-btn" onClick={searchDate}>Submit</button>
                        </div>

                        <div
                            className="input-group col-lg-3"
                            style={{ width: "auto", height: "35px", marginTop: "20px" }}
                        >
                            <span class="input-group-text" id="basic-addon1">
                                <BsSearch />
                            </span>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Search..."
                                aria-describedby="basic-addon1"
                                onChange={(e) => setSearchItem(e.target.value)}
                            />
                        </div>

                        <div className="col-lg-1 export-btn">
                            {/* <ReactHTMLTableToExcel
                id="export-to-excel"
                table="Export-table"
                filename="BookedTourPackage"
                sheet="BookedTourPackage"
                buttonText="Export"
                onClick={exportAllData}
              /> */}
                            <button className="button-download" onClick={exportAllData}>Export</button>
                        </div>
                    </div>
                    <div>
                        <Table responsive bordered className="admin-table-head" id="Export-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>User Name</th>
                                    <th>User Email</th>
                                    <th>User Number</th>
                                    <th>Activity Name</th>
                                    <th>Activity Location</th>
                                    <th>Travellers</th>
                                    <th>Booking Date</th>
                                    <th>Total Person</th>
                                    <th>Actual Price</th>
                                    <th>Discount(%)</th>
                                    <th>Discount Price</th>
                                    <th>Total Price</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedActivities?.filter((ele) => ele?.ActivityId?.actiitiyid === ActivityVendor?._id)?.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{item?.userId?.Fname} {item?.userId?.Lname}</td>
                                            <td>{item?.userId?.Email} </td>
                                            <td>{item?.userId?.Mobile} </td>
                                            <td>{parse(`<div>${item?.Title}</div>`)}</td>
                                            <td>
                                                {item?.location} <br />
                                                Country:{" "}
                                                <span style={{ color: "#d81d4a", fontSize: "18px" }}>
                                                    {item?.country}
                                                </span>{" "}
                                                ,<br />
                                                State:{" "}
                                                <span style={{ color: "#d81d4a" }}>
                                                    {item?.state}
                                                </span>{" "}
                                                ,<br />
                                                City:{" "}
                                                <span style={{ color: "#d81d4a" }}>
                                                    {item?.city}
                                                </span>
                                            </td>
                                            <td>
                                                <AiFillEye
                                                    style={{ cursor: 'pointer' }}
                                                    className="text-danger fs-5"
                                                    onClick={() => {
                                                        setTraveler(item)
                                                        handleShow1()
                                                    }} />
                                            </td>
                                            <td>{item?.checkindate}</td>
                                            <td>
                                                Adult:{" "}
                                                <span style={{ color: "green", fontSize: "18px" }}>
                                                    {item?.adultquantity}
                                                </span>{" "}
                                                ,<br />
                                                Children:{" "}
                                                <span style={{ color: "green" }}>
                                                    {item?.childrenquantity}
                                                </span>{" "}
                                            </td>
                                            <td>
                                                Adult:{" "}
                                                <span style={{ color: "green", fontSize: "18px" }}>
                                                    {" "}
                                                    ₹{(item?.adultprice) / (1 - item?.adultdiscount / 100)}
                                                </span>{" "}
                                                ,<br />
                                                Children:{" "}
                                                <span style={{ color: "green" }}>
                                                    {" "}
                                                    ₹{(item?.childrenprice) / (1 - item?.childrendiscount / 100)}
                                                </span>{" "}
                                                ,<br />
                                            </td>
                                            <td>
                                                Adult:{" "}
                                                <span style={{ color: "green", fontSize: "18px" }}>
                                                    {" "}
                                                    {item?.adultdiscount} %
                                                </span>{" "}
                                                ,<br />
                                                Children:{" "}
                                                <span style={{ color: "green" }}>
                                                    {" "}
                                                    {item?.childrendiscount} %
                                                </span>{" "}
                                            </td>
                                            <td>
                                                Adult:{" "}
                                                <span style={{ color: "green", fontSize: "18px" }}>
                                                    {" "}
                                                    ₹ {item?.adultprice}{" "}
                                                </span>{" "}
                                                ,<br />
                                                Children:{" "}
                                                <span style={{ color: "green" }}>
                                                    ₹ {item?.childrenprice}{" "}
                                                </span>{" "}
                                            </td>
                                            <td style={{ color: "green", fontSize: "18px" }}>₹{item?.AllTotalamount}</td>
                                            <td>
                                                <div
                                                    className="d-flex gap-2 fs-4 align-items-center"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <MdDelete
                                                        className="text-danger"
                                                        onClick={() => handleShow(item?._id)}
                                                    />
                                                    {/* <Link to=""><AiFillEye /> </Link> */}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className="my-3">
                        <Stack spacing={2}>
                            <Pagination
                                count={pageCount}
                                onChange={(event, value) => {
                                    changePage(value - 1)
                                }}
                                color="primary"
                            />
                        </Stack>
                    </div>
                </Container>

                {/* Delete Modal */}

                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <img
                                style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "5px",
                                    marginBottom: "10px",
                                }}
                                src="./img/delete-btn.png"
                                alt=""
                            />
                            <h4 className="fw-bold text-dark mb-2">Are You Sure</h4>
                            <p>This data will be removed permanently</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => DeleteBookedActivity()}>
                            <FontAwesomeIcon icon={faCancel} className=" me-2" />
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Traveller Model */}
                <Modal
                    show={show1}
                    onHide={handleClose1}
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <p className="fw-bold">Total Trvelers : <span className="text-danger">{Traveler?.TravelersData?.length}</span></p>
                        <Table bordered className="sdfsd-table-head">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Traveler Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Traveler?.TravelersData?.map((item, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{item?.TravellerFirstName} {item?.TravellerLastName}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className="fw-bold">Contact Details :</td>
                                    <td>
                                        Email:{" "}
                                        <span style={{ color: "green", fontSize: "18px" }}>
                                            {Traveler?.TravelerEmalil}
                                        </span>{" "}
                                        ,<br />
                                        Number:{" "}
                                        <span style={{ color: "green" }}>
                                            {Traveler?.TravelerNumber}
                                        </span>{" "}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleClose1}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default VendorBookedActivity;
